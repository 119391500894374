import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SchoolServiceAdmin from '../services/SchoolServiceAdmin';
import UploadExcel from './AfterLoginPages/UploadExcel';
import CreateDeleteAbsenceListForTeacher from './AfterLoginPages/CreateDeleteAbsenceListForTeacher';
import ArchivedStudentList from './AfterLoginPages/ArchivedStudentsList';
import booksImage from '../imgs/books.jpg';
import holidaysImage from '../imgs/holiday.jpg';
import sapImage from '../imgs/sap.jpg';
import { levelColors, levelTranslations } from './ConstFuncs';
import AdminDisplayTeacherAttendanceCheck from './AfterLoginPages/AdminDisplayTeacherAttendanceCheck';

const SchoolSuperAdmin = ({ data, setData }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [allStudents, setAllStudents] = useState(null);
  const [allTeachers, setAllTeachers] = useState(null);
  const [allArchivedStudents, setAllArchivedStudents] =
    useState(null);

  const [isStudentsTableVisible, setIsStudentsTableVisible] =
    useState(false);
  const [isTeachersTableVisible, setIsTeachersTableVisible] =
    useState(false);

  const [isEmailSendingEnabled, setIsEmailSendingEnabled] =
    useState(false);

  // Check if user is a SUPER_ADMIN
  const isSuperAdmin = data.roles?.some(
    (role) => role.roleCode === 'SUPER_ADMIN'
  );

  useEffect(() => {
    if (!data || !isSuperAdmin) {
      localStorage.clear();
      navigate('/');
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const [students, archivedStudents, teachers, emailStatus] =
          await Promise.all([
            SchoolServiceAdmin.getAllStudents(),
            SchoolServiceAdmin.getAllArchivedStudents(),
            SchoolServiceAdmin.getAllTeachers(),
            SchoolServiceAdmin.getEmailSendingStatus(),
          ]);

        setAllStudents(students.data);
        setAllArchivedStudents(archivedStudents.data);
        setAllTeachers(teachers.data);

        setIsEmailSendingEnabled(emailStatus.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };
    fetchData();
  }, [data, isSuperAdmin, navigate]);

  const refreshAllTeacher = async () => {
    setLoading(true);
    try {
      const response2 = await SchoolServiceAdmin.getAllTeachers();
      setAllTeachers(response2.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const toggleStudentsTableVisibility = () => {
    setIsStudentsTableVisible(!isStudentsTableVisible);
  };
  const toggleTeachersTableVisibility = () => {
    setIsTeachersTableVisible(!isTeachersTableVisible);
  };

  const handleCheckboxChange = async (event) => {
    const isChecked = event.target.checked;
    try {
      await SchoolServiceAdmin.updateSendEmailFlag(isChecked);
      setIsEmailSendingEnabled(isChecked);
      console.log('Update successful:');
    } catch (error) {
      console.error('Error updating send email flag:', error);
    }
  };

  const fetchArchivedStudents = async () => {
    try {
      const response =
        await SchoolServiceAdmin.getAllArchivedStudents();
      setAllArchivedStudents(response.data);
    } catch (error) {
      console.error('Error fetching archived students:', error);
    }
  };

  return (
    <div className="container mx-auto my-8">
      <ArchivedStudentList
        allArchivedStudents={allArchivedStudents}
        loading={loading}
        fetchArchivedStudents={fetchArchivedStudents}
        isSuperAdmin={isSuperAdmin}
      />
      <div className="inline-flex items-center justify-center w-full ">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-green-600"
            checked={isEmailSendingEnabled}
            onChange={handleCheckboxChange}
          />
          <span className="ml-2 text-gray-700">
            Enable Email Sending
          </span>
        </label>
      </div>
      <div className="inline-flex items-center justify-center w-full ">
        <hr className="w-96 h-1 my-8 bg-cyan-800 border-0 rounded " />
        <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 space-x-2">
          <button
            onClick={toggleStudentsTableVisibility}
            className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
          >
            {isStudentsTableVisible
              ? 'Hide All Students'
              : 'Show All Students'}
          </button>
          <button
            onClick={() => {
              navigate('/superadmin/createstudent');
            }}
            className="rounded bg-green-700 text-white px-6 py-2 font-semibold hover:bg-green-800"
          >
            + Add New Student
          </button>
        </div>
      </div>

      {!loading && isStudentsTableVisible && (
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          <table className="min-w-full border rounded overflow-hidden">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="py-2 px-4">ID</th>
                <th className="py-2 px-4">First Name</th>
                <th className="py-2 px-4">Last Name</th>
                <th className="py-2 px-4">First Name Arabic</th>
                <th className="py-2 px-4">Last Name Arabic</th>
                <th className="py-2 px-4">Parents Email</th>
                <th className="py-2 px-4">Class Days Of Week</th>
                <th className="py-2 px-4">Teacher User Name</th>
                <th className="py-2 px-4">Teacher Arabic Name</th>
                <th className="py-2 px-4">Student Level</th>
              </tr>
            </thead>
            <tbody>
              {allStudents?.map((myStudent) => (
                <tr
                  className="border-b text-center"
                  key={myStudent.id}
                >
                  <td className="py-2 px-4 ">{myStudent.id}</td>
                  <td className="py-2 px-4">{myStudent.firstName}</td>
                  <td className="py-2 px-4">{myStudent.lastName}</td>
                  <td className="py-2 px-4">
                    {myStudent.firstNameArabic}
                  </td>
                  <td className="py-2 px-4">
                    {myStudent.lastNameArabic}
                  </td>
                  <td className="py-2 px-4">{myStudent.email}</td>
                  <td className="py-2 px-4">
                    {myStudent.classDaysOfWeek.map((e) => e + ', ')}
                  </td>
                  <td className="py-2 px-4">
                    {myStudent.teacherUserName}
                  </td>
                  <td className="py-2 px-4">
                    {myStudent.teacherFirstName}
                  </td>
                  <td
                    className={`py-2 font-semibold text-gray-300 ${
                      myStudent.level != null
                        ? levelColors[myStudent.level]
                        : 'bg-slate-600'
                    }`}
                  >
                    {myStudent.level != null
                      ? levelTranslations[myStudent.level]
                      : 'لم يتم الاختيار بعد'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <hr className="w-960 h-2  mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />

      <div className="inline-flex items-center justify-center w-full">
        <hr className="w-96 h-1 my-8 bg-cyan-800 border-0 rounded " />
        <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 space-x-2">
          <button
            onClick={toggleTeachersTableVisibility}
            className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
          >
            {isTeachersTableVisible
              ? 'Hide All Teachers'
              : 'Show All Teachers'}
          </button>
          <button
            onClick={() => {
              navigate('/superadmin/createuser');
            }}
            className="rounded bg-green-700 text-white px-6 py-2 font-semibold hover:bg-green-800"
          >
            + Add New Teacher
          </button>
        </div>
      </div>

      {!loading && isTeachersTableVisible && (
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          <table className="min-w-full border rounded overflow-hidden">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="py-2 px-4">ID</th>
                <th className="py-2 px-4">Email</th>
                <th className="py-2 px-4">First Name</th>
                <th className="py-2 px-4">Last Name</th>
                <th className="py-2 px-4">User Name</th>
                <th className="py-2 px-4">Class Abbrev</th>
                <th className="py-2 px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {allTeachers?.map((myTeacher) => (
                <tr
                  className="border-b text-center"
                  key={myTeacher.id}
                >
                  <td className="py-2 px-4 ">{myTeacher.id}</td>
                  <td className="py-2 px-4 ">{myTeacher.email}</td>
                  <td className="py-2 px-4">{myTeacher.firstName}</td>
                  <td className="py-2 px-4">{myTeacher.lastName}</td>
                  <td className="py-2 px-4 ">{myTeacher.username}</td>
                  <td className="py-2 px-4 ">
                    {myTeacher.classAbbrev}
                  </td>
                  <td className="py-2 px-4 space-x-2 ">
                    <button
                      onClick={() =>
                        navigate(
                          `/superadmin/updatestudentgroup/${myTeacher.id}`
                        )
                      }
                      className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
                    >
                      update student group
                    </button>
                    <button
                      onClick={async () => {
                        await SchoolServiceAdmin.deleteTeacher(
                          myTeacher.id
                        );
                        refreshAllTeacher();
                      }}
                      className="rounded bg-red-700 text-white px-6 py-2 font-semibold hover:bg-red-800"
                    >
                      delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />

      <UploadExcel />
      <CreateDeleteAbsenceListForTeacher allTeachers={allTeachers} />
      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />

      <div className="container my-8">
        <h1 className="text-2xl font-semibold text-center mb-8">
          School Super Admin Services
        </h1>
        <div className="flex justify-center space-x-4">
          <div
            className="max-w-sm bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105"
            onClick={() => navigate('/superadmin/books-services')}
          >
            <div className="relative">
              <img
                //src="https://via.placeholder.com/400x200" // Replace this with an appropriate image URL
                src={booksImage}
                alt="Books Services"
                className="w-full h-48 object-cover"
              />
            </div>
            <div className="p-4">
              <h2 className="text-xl font-semibold text-gray-800 text-center">
                Books
              </h2>
            </div>
          </div>
          <div
            className="max-w-sm bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105"
            onClick={() => navigate('/superadmin/holidays-services')}
          >
            <div className="relative">
              <img
                //src="https://via.placeholder.com/400x200" // Replace this with an appropriate image URL
                src={holidaysImage}
                alt="Holidays Services"
                className="w-full h-48 object-cover"
              />
            </div>
            <div className="p-4">
              <h2 className="text-xl font-semibold text-gray-800 text-center">
                Holidays
              </h2>
            </div>
          </div>
          <div
            className="max-w-sm bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105"
            onClick={() =>
              navigate('/superadmin/teachers-sap-services')
            }
          >
            <div className="relative">
              <img
                //src="https://via.placeholder.com/400x200" // Replace this with an appropriate image URL
                src={sapImage}
                alt="System Application and Product(SAP) Services"
                className="w-full h-48 object-cover"
              />
            </div>
            <div className="p-4">
              <h2 className="text-xl font-semibold text-gray-800 text-center">
                SAP
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolSuperAdmin;
