import React, { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import BookService from '../../services/BookService';
import { useNavigate } from 'react-router-dom';
import schoolServiceAdminInstance from '../../services/SchoolServiceAdmin';
import TeacherClass from '../AfterLoginPages/TeacherClass';
import LogsDisplay from '../AfterLoginPages/LogsDisplay';

const BooksServices = ({ data }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selectedTeacher, setSelectedTeacher] = useState(null);

  const [allBooks, setAllBooks] = useState([]); // Books state
  const [allTeachers, setAllTeachers] = useState(null);
  const [isTeacherListVisible, setIsTeacherListVisible] =
    useState(false);

  const [isBooksTableVisible, setIsBooksTableVisible] =
    useState(false); // Books table toggle
  const [newBook, setNewBook] = useState({
    title: '',
    isbn: '',
    totalCopies: 0,
    price: 0,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editBook, setEditBook] = useState(null); // For editing a book
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Edit modal visibility

  const [isIssueBookModalOpen, setIsIssueBookModalOpen] =
    useState(false);

  const [selectedBookIds, setSelectedBookIds] = useState([]); // Track selected books for the teacher
  const [bookQuantities, setBookQuantities] = useState({}); // Track quantities for each book

  // State for showing/hiding Books Email Notification Service
  const [isBooksEmailVisible, setIsBooksEmailVisible] =
    useState(false);

  // Check if user is a SUPER_ADMIN
  const isSuperAdmin = data.roles?.some(
    (role) => role.roleCode === 'SUPER_ADMIN'
  );

  useEffect(() => {
    if (!data || !isSuperAdmin) {
      localStorage.clear();
      navigate('/');
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const [teachers, books] = await Promise.all([
          schoolServiceAdminInstance.getAllTeachers(),
          BookService.getAllBooks(), // Fetch all books
        ]);

        setAllTeachers(teachers.data);
        setAllBooks(books.data); // Set books
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };
    fetchData();
  }, [data, isSuperAdmin, navigate]);

  // Refresh books
  const refreshBooks = async () => {
    setLoading(true);
    try {
      const booksResponse = await BookService.getAllBooks();
      setAllBooks(booksResponse.data);
    } catch (error) {
      console.error('Error refreshing books:', error);
    }
    setLoading(false);
  };

  const toggleBooksTableVisibility = () => {
    setIsBooksTableVisible(!isBooksTableVisible);
  };

  const handleAddBook = async () => {
    try {
      await BookService.createBook(newBook);
      setNewBook({ title: '', isbn: '' });
      refreshBooks();
      setIsModalOpen(false);
      alert('Book added successfully.');
    } catch (error) {
      console.error('Error adding book:', error);
    }
  };

  const openEditModal = (book) => {
    setEditBook(book);
    setIsEditModalOpen(true);
  };

  const handleUpdateBook = async () => {
    try {
      // Construct the query string with the status parameter

      await BookService.updateBook(
        editBook.id,
        editBook,
        editBook.status
      );
      refreshBooks(); // Refresh book list after update
      setIsEditModalOpen(false);
      alert('Book updated successfully.');
    } catch (error) {
      console.error('Error updating book:', error);
    }
  };

  const openIssueBookModal = (teacher) => {
    setSelectedTeacher(teacher);
    setSelectedBookIds([]);
    setBookQuantities({});
    setIsIssueBookModalOpen(true);
  };

  const handleIssueBooksToTeacher = async () => {
    try {
      const issueDetails = selectedBookIds.map((bookId) => ({
        bookId,
        quantity: bookQuantities[bookId] || 0,
      }));

      await BookService.issueBooksToTeacher(
        selectedTeacher.id,
        issueDetails
      ); // Backend API call
      refreshBooks(); // Refresh book list to update available copies
      setIsIssueBookModalOpen(false);
      alert(
        `Books issued to ${selectedTeacher.firstName} successfully.`
      );
    } catch (error) {
      console.error('Error issuing books:', error);
      alert('Failed to issue books.');
    }
  };

  return (
    <div className="container mx-auto my-8">
      <div className="container mx-auto my-8">
        <div className="inline-flex items-center justify-center w-full">
          <h1 className="md:text-2xl text-2xl text-center py-2 font-semibold text-cyan-900">
            Books System Logs
          </h1>
        </div>
        <LogsDisplay /> {/* Include the LogsDisplay component */}
      </div>
      <hr className="w-960 h-2  mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />

      <div className="inline-flex items-center justify-center w-full">
        <h1 className="md:text-2xl text-2xl text-center py-2 font-semibold text-cyan-900 ">
          Books CRUD Service
        </h1>
      </div>
      {/* Book Table Toggle */}
      <div className="inline-flex items-center justify-center w-full">
        <hr className="w-96 h-1 my-8 bg-cyan-800 border-0 rounded" />
        <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 space-x-2">
          <button
            onClick={toggleBooksTableVisibility}
            className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
          >
            {isBooksTableVisible
              ? 'Hide All Books'
              : 'Show All Books'}
          </button>
          <button
            onClick={() => setIsModalOpen(true)}
            className="rounded bg-green-700 text-white px-6 py-2 font-semibold hover:bg-green-800"
          >
            + Add Book
          </button>
        </div>
      </div>

      {/* Books Table */}
      {isBooksTableVisible && (
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          <table className="min-w-full border rounded overflow-hidden">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="py-2 px-4">ID</th>
                <th className="py-2 px-4">Title</th>
                <th className="py-2 px-4">ISBN</th>
                <th className="py-2 px-4">Total Copies</th>
                <th className="py-2 px-4">Available Copies</th>
                <th className="py-2 px-4">Price</th>
                <th className="py-2 px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {allBooks.map((book) => (
                <tr key={book.id} className="border-b text-center">
                  <td className="py-2 px-4">{book.id}</td>
                  <td className="py-2 px-4">{book.title}</td>
                  <td className="py-2 px-4">{book.isbn}</td>
                  <td className="py-2 px-4">{book.totalCopies}</td>
                  <td className="py-2 px-4">
                    {book.availableCopies}
                  </td>
                  <td className="py-2 px-4">
                    ${book.price.toFixed(2)}
                  </td>
                  <td className="py-2 px-4">
                    <button
                      onClick={() => openEditModal(book)}
                      className="rounded bg-blue-700 text-white px-6 py-2 mx-1 font-semibold hover:bg-blue-800"
                    >
                      Edit
                    </button>
                    <button
                      onClick={async () => {
                        await BookService.deleteBook(book.id);
                        refreshBooks();
                        alert('Book deleted successfully.');
                      }}
                      className="rounded bg-red-700 text-white px-6 py-2 mx-1 font-semibold hover:bg-red-800"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Add Book Modal */}
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="relative z-10"
      >
        <div className="fixed inset-0 bg-black bg-opacity-50" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-md bg-white rounded-lg p-6">
            <Dialog.Title className="text-lg font-medium text-gray-900">
              Add New Book
            </Dialog.Title>
            <div className="mt-4">
              <label className="block text-gray-700">Title</label>
              <input
                type="text"
                className="w-full mt-2 p-2 border rounded"
                value={newBook.title}
                onChange={(e) =>
                  setNewBook({ ...newBook, title: e.target.value })
                }
              />
            </div>
            <div className="mt-4">
              <label className="block text-gray-700">ISBN</label>
              <input
                type="text"
                className="w-full mt-2 p-2 border rounded"
                value={newBook.isbn}
                onChange={(e) =>
                  setNewBook({ ...newBook, isbn: e.target.value })
                }
              />
            </div>
            <div className="mt-4">
              <label className="block text-gray-700">
                Total Copies
              </label>
              <input
                type="number"
                className="w-full mt-2 p-2 border rounded"
                value={newBook.totalCopies}
                onChange={(e) =>
                  setNewBook({
                    ...newBook,
                    totalCopies: parseInt(e.target.value, 10),
                  })
                }
              />
            </div>
            <div className="mt-4">
              <label className="block text-gray-700">Price</label>
              <input
                type="number"
                className="w-full mt-2 p-2 border rounded"
                value={newBook.price}
                onChange={(e) =>
                  setNewBook({
                    ...newBook,
                    price: parseFloat(e.target.value) || 0, // Ensure it's a valid number
                  })
                }
              />
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="rounded bg-gray-500 text-white px-4 py-2 font-semibold hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={handleAddBook}
                className="rounded bg-green-700 text-white px-4 py-2 font-semibold hover:bg-green-800"
              >
                Save
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* Edit Book Modal */}
      <Dialog
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        className="relative z-10"
      >
        <div className="fixed inset-0 bg-black bg-opacity-50" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-md bg-white rounded-lg p-6">
            <Dialog.Title className="text-lg font-medium text-gray-900">
              Edit Book
            </Dialog.Title>
            <div className="mt-4">
              <label className="block text-gray-700">Title</label>
              <input
                type="text"
                className="w-full mt-2 p-2 border rounded"
                value={editBook?.title || ''}
                onChange={(e) =>
                  setEditBook({ ...editBook, title: e.target.value })
                }
              />
            </div>
            <div className="mt-4">
              <label className="block text-gray-700">ISBN</label>
              <input
                type="text"
                className="w-full mt-2 p-2 border rounded"
                value={editBook?.isbn || ''}
                onChange={(e) =>
                  setEditBook({ ...editBook, isbn: e.target.value })
                }
              />
            </div>
            <div className="mt-4">
              <label className="block text-gray-700">
                Total Copies
              </label>
              <input
                type="number"
                className="w-full mt-2 p-2 border rounded"
                value={editBook?.totalCopies || 0}
                onChange={(e) =>
                  setEditBook({
                    ...editBook,
                    totalCopies: parseInt(e.target.value, 10),
                  })
                }
              />
            </div>
            <div className="mt-4">
              <label className="block text-gray-700">Status</label>
              <select
                className="w-full mt-2 p-2 border rounded"
                value={editBook?.status || 'UPDATED'} // Ensure fallback to 'UPDATED'
                onChange={(e) => {
                  const newStatus = e.target.value || 'UPDATED';
                  setEditBook({
                    ...editBook,
                    status: newStatus,
                  });
                }}
              >
                <option value="UPDATED">Updated</option>
                <option value="ADDED">Added to Inventory</option>
                <option value="RETURNED">Returned</option>
                <option value="LOST">Lost</option>
              </select>
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setIsEditModalOpen(false)}
                className="rounded bg-gray-500 text-white px-4 py-2 font-semibold hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdateBook}
                className="rounded bg-green-700 text-white px-4 py-2 font-semibold hover:bg-green-800"
              >
                Save
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />
      {/* Issue Books to Teachers Section */}
      <div>
        <div className="inline-flex items-center justify-center w-full">
          <h1 className="md:text-2xl text-2xl text-center py-2 font-semibold text-cyan-900 ">
            Give Books to Teacher Service
          </h1>
        </div>

        <div className="inline-flex items-center justify-center w-full">
          <button
            onClick={() =>
              setIsTeacherListVisible(!isTeacherListVisible)
            }
            className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
          >
            {isTeacherListVisible ? 'Hide List' : 'Show List'}
          </button>
        </div>

        {isTeacherListVisible &&
          allTeachers.map((teacher) => (
            <div key={teacher.id} className="my-4">
              <div className="grid grid-cols-2 items-center gap-x-4 py-2 justify-center">
                <div className="text-center">
                  <h2 className="text-lg font-semibold text-gray-700">
                    {teacher.firstName} | {teacher.lastName} |{' '}
                    {teacher.classAbbrev}
                  </h2>
                </div>
                <div className="text-center">
                  <button
                    onClick={() => openIssueBookModal(teacher)}
                    className="rounded bg-blue-600 text-white px-4 py-2 font-semibold hover:bg-blue-700"
                  >
                    Give Books To Teacher
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />

      <h1 className="md:text-2xl text-2xl text-center py-2 font-semibold text-cyan-900 ">
        Books Email Notification Service For Students and Classes
      </h1>
      <div className="inline-flex items-center justify-center w-full">
        <button
          onClick={() => setIsBooksEmailVisible(!isBooksEmailVisible)}
          className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
        >
          {isBooksEmailVisible
            ? 'Hide Books Email Notification'
            : 'Show Books Email Notification'}
        </button>
      </div>

      {isBooksEmailVisible && (
        <>
          {loading ? (
            <p>Loading...</p>
          ) : (
            allTeachers.map((teacher) => (
              <TeacherClass
                key={teacher.id}
                teacher={teacher}
                books={allBooks}
              />
            ))
          )}
        </>
      )}

      <Dialog
        open={isIssueBookModalOpen}
        onClose={() => setIsIssueBookModalOpen(false)}
        className="relative z-10"
      >
        <div className="fixed inset-0 bg-black bg-opacity-50" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-md bg-white rounded-lg p-6 shadow-lg">
            <Dialog.Title className="text-lg font-medium text-gray-900">
              Issue Books to {selectedTeacher?.firstName}{' '}
              {selectedTeacher?.lastName}
            </Dialog.Title>
            <div className="mt-4">
              {allBooks.map((book) => {
                const isChecked = selectedBookIds.includes(book.id);

                return (
                  <div
                    key={book.id}
                    className="flex items-center justify-between mb-4 border-b pb-2"
                  >
                    <div>
                      <label className="text-gray-700 font-medium">
                        {book.title}
                      </label>
                      <p className="text-sm text-gray-500">
                        Available: {book.availableCopies}
                      </p>
                    </div>
                    <div className="flex items-center space-x-4">
                      {/* Input Field */}
                      <input
                        type="number"
                        className="w-20 p-2 border rounded focus:ring-2 focus:ring-blue-500 disabled:bg-gray-200 disabled:cursor-not-allowed"
                        min={0}
                        max={book.availableCopies}
                        disabled={!isChecked}
                        value={
                          isChecked
                            ? bookQuantities[book.id] || ''
                            : ''
                        }
                        onChange={(e) => {
                          setBookQuantities({
                            ...bookQuantities,
                            [book.id]: parseInt(e.target.value, 10),
                          });
                        }}
                      />

                      {/* Checkbox */}
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedBookIds([
                              ...selectedBookIds,
                              book.id,
                            ]);
                          } else {
                            setSelectedBookIds(
                              selectedBookIds.filter(
                                (id) => id !== book.id
                              )
                            );
                            setBookQuantities({
                              ...bookQuantities,
                              [book.id]: '', // Reset input value when unchecked
                            });
                          }
                        }}
                        className="form-checkbox h-6 w-6 text-green-600 rounded border-gray-300 focus:ring-2 focus:ring-green-500"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setIsIssueBookModalOpen(false)}
                className="rounded bg-gray-500 text-white px-4 py-2 font-semibold hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={handleIssueBooksToTeacher}
                className="rounded bg-green-700 text-white px-4 py-2 font-semibold hover:bg-green-800"
              >
                Issue
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />

      <div className="mx-auto text-center">
        <button
          onClick={() => {
            navigate('/schoolsuperadmin');
          }}
          className="rounded text-white px-6 py-2 font-semibold bg-cyan-700 hover:bg-cyan-800"
        >
          Back To Dashboard
        </button>
      </div>
    </div>
  );
};

export default BooksServices;
