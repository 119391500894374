import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { request } from '../../api/authenticationService';
import SchoolServiceAdmin from '../../services/SchoolServiceAdmin';

const CreateDeleteAbsenceListForTeacher = ({ allTeachers }) => {
  const dateRef = useRef();
  const [responseMsg, setResponseMsg] = useState('');

  useEffect(() => {
    if (responseMsg) {
      const timer = setTimeout(() => {
        setResponseMsg('');
      }, 5000);

      // Cleanup timer if the component unmounts or if responseMsg changes
      return () => clearTimeout(timer);
    }
  }, [responseMsg]);

  const createNewAbsenceListForTeacher = async () => {
    try {
      const response =
        await SchoolServiceAdmin.createNewAbsenceListForTeacherOnDate(
          selectedOption,
          dateRef.current.value
        );
      setResponseMsg(response.data);
    } catch (error) {
      console.log(error.response.data);
      setResponseMsg(error.response.data);
    }
  };
  const deleteAbsenceListForTeacher = async () => {
    try {
      const response =
        await SchoolServiceAdmin.deleteAbsenceListForTeacherOnDate(
          selectedOption,
          dateRef.current.value
        );
      setResponseMsg(response.data);
    } catch (error) {
      console.log(error.response.data);
      setResponseMsg(error.response.data);
    }
  };

  const [selectedOption, setSelectedOption] = useState(
    allTeachers?.length > 0 ? allTeachers[0].id : ''
  );
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <div className="inline-flex items-center justify-center w-full">
        <h1 className="md:text-2xl text-2xl text-center py-2 font-semibold text-cyan-900 ">
          Create/Delete Absence List For Teacher On specific Date
        </h1>
      </div>
      <div className="mt-5 flex items-center justify-center w-full">
        <div className="relative px-4 bg-white flex space-x-2">
          <div className="flex-col items-center mx-auto space-y-4">
            <select
              value={selectedOption}
              onChange={handleOptionChange}
              className="w-full appearance-none bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            >
              {allTeachers?.map((t) => (
                <option key={t.id} value={t.id}>
                  {t.id +
                    ' - ' +
                    t.firstName +
                    ' - ' +
                    t.username +
                    ' - ' +
                    t.classAbbrev}
                </option>
              ))}
            </select>
          </div>
          <input
            className="py-1 block border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 hover:border-black transition-all duration-300"
            type="date"
            ref={dateRef}
          />
          <button
            onClick={createNewAbsenceListForTeacher}
            className="rounded bg-green-700 text-white px-6 py-2 font-semibold hover:bg-green-800"
          >
            Create
          </button>
          <button
            onClick={deleteAbsenceListForTeacher}
            className="rounded bg-red-700 text-white px-6 py-2 font-semibold hover:bg-red-800"
          >
            Delete
          </button>
        </div>
      </div>
      <div className="inline-flex items-center justify-center w-full">
        <h1 className="md:text-2xl text-2xl text-center py-2 font-semibold text-cyan-900 ">
          {responseMsg}
        </h1>
      </div>
    </>
  );
};

export default CreateDeleteAbsenceListForTeacher;
