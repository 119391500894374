import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminDisplayTeacherAttendanceCheck from '../AfterLoginPages/AdminDisplayTeacherAttendanceCheck';
import schoolServiceAdminInstance from '../../services/SchoolServiceAdmin';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable'; // To enable table support in jsPDF
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const TeachersSAP = () => {
  const navigate = useNavigate();

  // Visibility States for Each Section

  const [
    isFetchVerifyDeleteRecordsVisible,
    setIsFetchVerifyDeleteRecordsVisible,
  ] = useState(false);
  const [
    isAttendanceRecordsPerTeacherVisible,
    setIsAttendanceRecordsPerTeacherVisible,
  ] = useState(false);
  const [isExportRecordsVisible, setIsExportRecordsVisible] =
    useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [records, setRecords] = useState([]);
  const [error, setError] = useState('');

  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectAllTeachers, setSelectAllTeachers] = useState(false);

  const [teacherList, setTeacherList] = useState([]);
  const [selectedTeacherId, setSelectedTeacherId] = useState('');
  const [attendanceStartDate, setAttendanceStartDate] = useState('');
  const [attendanceEndDate, setAttendanceEndDate] = useState('');
  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const [attendanceRecordsError, setAttendanceRecordsError] =
    useState('');
  const [includeAlternative, setIncludeAlternative] = useState(false);
  const [selectedTeacherIds, setSelectedTeacherIds] = useState([]);

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response =
          await schoolServiceAdminInstance.getAllTeachers();
        setTeacherList(response.data);
      } catch (error) {
        console.error('Error fetching teacher list:', error);
      }
    };

    fetchTeachers();
  }, []);

  const handleFetchAttendanceRecords = async () => {
    if (
      (!selectedTeacherId && !selectAllTeachers) ||
      !attendanceStartDate ||
      !attendanceEndDate
    ) {
      alert(
        'Please select a teacher and specify both start and end dates.'
      );
      return;
    }

    try {
      let response;

      if (selectAllTeachers) {
        response = includeAlternative
          ? await schoolServiceAdminInstance.getAllTeacherRecordsByDateRange(
              attendanceStartDate,
              attendanceEndDate
            )
          : await schoolServiceAdminInstance.getAllTeacherRecordsByDateRangeByPrimaryTeacher(
              attendanceStartDate,
              attendanceEndDate
            );
      } else {
        response = includeAlternative
          ? await schoolServiceAdminInstance.getTeacherRecordsByDateRange(
              selectedTeacherId,
              attendanceStartDate,
              attendanceEndDate
            )
          : await schoolServiceAdminInstance.getTeacherRecordsByDateRangeByPrimaryTeacher(
              selectedTeacherId,
              attendanceStartDate,
              attendanceEndDate
            );
      }

      setAttendanceRecords(response.data);
      setAttendanceRecordsError('');
    } catch (error) {
      setAttendanceRecordsError(
        'Failed to fetch attendance records. Please try again.'
      );
    }
  };

  const exportToPDF = () => {
    const teacher = teacherList.find(
      (t) => t.id == selectedTeacherId
    );

    const teacherName = selectAllTeachers
      ? 'All Teachers'
      : `${teacher.userName}`;
    const teacherId = selectAllTeachers ? 'All' : teacher.id;
    const teacherClass = selectAllTeachers
      ? 'All Classes'
      : teacher.classAbbrev;

    const fileName = selectAllTeachers
      ? `attendance-all-teachers-${attendanceStartDate}_to_${attendanceEndDate}.pdf`
      : `attendance-${teacher.id}-${teacher.classAbbrev}-${attendanceStartDate}_to_${attendanceEndDate}.pdf`;

    const doc = new jsPDF();

    // Set font for multilingual support (default fonts in jsPDF support Arabic and German)
    doc.setFont('helvetica', 'normal');

    const pageWidth = doc.internal.pageSize.getWidth(); // Get the page width

    // Function to calculate centered position
    const centerText = (text) =>
      (pageWidth - doc.getTextWidth(text)) / 2;

    // Add centered text
    doc.text(
      'Teacher Attendance Records',
      centerText('Teacher Attendance Records'),
      10
    );
    doc.text(
      `Teacher: ${teacherName}`,
      centerText(`Teacher: ${teacherName}`),
      20
    );
    doc.text(
      `Teacher ID: ${teacherId}`,
      centerText(`Teacher ID: ${teacherId}`),
      30
    );
    doc.text(
      `Class: ${teacherClass}`,
      centerText(`Class: ${teacherClass}`),
      40
    );
    doc.text(
      `Date Range: ${attendanceStartDate} to ${attendanceEndDate}`,
      centerText(
        `Date Range: ${attendanceStartDate} to ${attendanceEndDate}`
      ),
      50
    );

    // Prepare table data with separators for teachers
    const tableData = [];
    let lastTeacherId = null;

    attendanceRecords.forEach((record) => {
      if (lastTeacherId !== record.primaryTeacherId) {
        // Add a separator row for each teacher when "All Teachers" is selected
        if (lastTeacherId) {
          tableData.push(['', '', '', '', '', '', '', '']);
        }
        tableData.push([
          `Teacher: ${record.primaryTeacherName}`,
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ]);
      }
      tableData.push([
        record.attendanceDate,
        record.primaryTeacherId,
        record.primaryTeacherName,
        record.wasPresent ? 'Present' : 'Absent',
        record.alternativeTeacherId || 'N/A',
        record.alternativeTeacherName || 'N/A',
        record.classAbbrev,
        record.status,
      ]);
      lastTeacherId = record.primaryTeacherId;
    });

    doc.autoTable({
      startY: 60,
      head: [
        [
          'Date',
          'Teacher ID',
          'Teacher Name',
          'Presence',
          'Alt Teacher ID',
          'Alt Teacher Name',
          'Class',
          'Status',
        ],
      ],
      body: tableData,
    });

    doc.save(fileName);
  };

  //   const exportToCSV = () => {
  //     const teacher = teacherList.find(
  //       (t) => t.id == selectedTeacherId
  //     );

  //     const teacherName = selectAllTeachers
  //       ? 'All Teachers'
  //       : `${teacher.firstName} ${teacher.lastName}`;
  //     const teacherId = selectAllTeachers ? 'All' : teacher.id;
  //     const teacherClass = selectAllTeachers
  //       ? 'All Classes'
  //       : teacher.classAbbrev;

  //     const fileName = selectAllTeachers
  //       ? `attendance-all-teachers-${attendanceStartDate}_to_${attendanceEndDate}.csv`
  //       : `attendance-${teacher.id}-${teacher.classAbbrev}-${attendanceStartDate}_to_${attendanceEndDate}.csv`;

  //     const csvData = [];
  //     let lastTeacherId = null;

  //     attendanceRecords.forEach((record) => {
  //       if (lastTeacherId !== record.primaryTeacherId) {
  //         // Add a separator row for each teacher when "All Teachers" is selected
  //         if (lastTeacherId) {
  //           csvData.push({});
  //         }
  //         csvData.push({
  //           Teacher: `Teacher: ${record.primaryTeacherName}`,
  //           'Teacher ID': '',
  //           'Teacher Name': '',
  //           Presence: '',
  //           'Alt Teacher ID': '',
  //           'Alt Teacher Name': '',
  //           Class: '',
  //           Status: '',
  //         });
  //       }
  //       csvData.push({
  //         Date: record.attendanceDate,
  //         'Teacher ID': record.primaryTeacherId,
  //         'Teacher Name': record.primaryTeacherName,
  //         Presence: record.wasPresent ? 'Present' : 'Absent',
  //         'Alt Teacher ID': record.alternativeTeacherId || 'N/A',
  //         'Alt Teacher Name': record.alternativeTeacherName || 'N/A',
  //         Class: record.classAbbrev,
  //         Status: record.status,
  //       });
  //       lastTeacherId = record.primaryTeacherId;
  //     });

  //     const descriptiveData = attendanceRecords.map((record) => ({
  //       Description: record.wasPresent
  //         ? `Lehrer ${record.primaryTeacherName} war da am Datum: ${record.attendanceDate}.`
  //         : `Lehrer ${
  //             record.primaryTeacherName
  //           } war nicht da und Lehrer ${
  //             record.alternativeTeacherName || 'N/A'
  //           } hat Vertretung am Datum: ${record.attendanceDate}.`,
  //     }));

  //     const fullCSVData = [
  //       {
  //         Teacher: teacherName,
  //         'Teacher ID': teacherId,
  //         Class: teacherClass,
  //         'Date Range': `${attendanceStartDate} to ${attendanceEndDate}`,
  //       },
  //       ...csvData,
  //       ...descriptiveData,
  //     ];

  //     const csv = Papa.unparse(fullCSVData);
  //     const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  //     const url = URL.createObjectURL(blob);

  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', fileName);
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   };

  const fetchAndExportPDF = async (teacherId, zip) => {
    try {
      const response =
        await schoolServiceAdminInstance.getTeacherRecordsByDateRange(
          teacherId,
          attendanceStartDate,
          attendanceEndDate
        );
      const attendanceRecords = response.data;

      if (attendanceRecords.length === 0) {
        console.warn(
          `No attendance records found for teacher with ID ${teacherId}.`
        );
        return;
      }

      const teacher = teacherList.find((t) => t.id === teacherId);
      const teacherName = `${teacher.firstName} ${teacher.lastName}`;
      const teacherClass = teacher.classAbbrev;
      const doc = new jsPDF();

      const fileName = `attendance-${teacher.id}-${teacher.classAbbrev}-${attendanceStartDate}_to_${attendanceEndDate}.pdf`;

      // Set font for multilingual support
      doc.setFont('helvetica', 'normal');

      const pageWidth = doc.internal.pageSize.getWidth(); // Get the page width
      const centerText = (text) =>
        (pageWidth - doc.getTextWidth(text)) / 2;

      // Add centered text
      doc.text(
        'Teacher Attendance Records',
        centerText('Teacher Attendance Records'),
        10
      );
      doc.text(
        `Teacher: ${teacherName}`,
        centerText(`Teacher: ${teacherName}`),
        20
      );
      doc.text(
        `Teacher ID: ${teacherId}`,
        centerText(`Teacher ID: ${teacherId}`),
        30
      );
      doc.text(
        `Class: ${teacherClass}`,
        centerText(`Class: ${teacherClass}`),
        40
      );
      doc.text(
        `Date Range: ${attendanceStartDate} to ${attendanceEndDate}`,
        centerText(
          `Date Range: ${attendanceStartDate} to ${attendanceEndDate}`
        ),
        50
      );

      // Prepare table data
      const tableData = [];
      let lastTeacherId = null;

      attendanceRecords.forEach((record) => {
        if (lastTeacherId !== record.primaryTeacherId) {
          if (lastTeacherId) {
            tableData.push(['', '', '', '', '', '', '', '']);
          }
          tableData.push([
            `Teacher: ${record.primaryTeacherName}`,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
          ]);
        }
        tableData.push([
          record.attendanceDate,
          record.primaryTeacherId,
          record.primaryTeacherName,
          record.wasPresent ? 'Present' : 'Absent',
          record.alternativeTeacherId || 'N/A',
          record.alternativeTeacherName || 'N/A',
          record.classAbbrev,
          record.status,
        ]);
        lastTeacherId = record.primaryTeacherId;
      });

      doc.autoTable({
        startY: 60,
        head: [
          [
            'Date',
            'Teacher ID',
            'Teacher Name',
            'Presence',
            'Alt Teacher ID',
            'Alt Teacher Name',
            'Class',
            'Status',
          ],
        ],
        body: tableData,
      });

      const pdfBlob = doc.output('blob');
      zip.file(fileName, pdfBlob); // Add the PDF to the ZIP archive
    } catch (error) {
      console.error(
        `Error fetching and exporting data for teacher ID ${teacherId}:`,
        error
      );
    }
  };

  const handleExportForAllSelected = async () => {
    if (selectedTeacherIds.length === 0) {
      alert('Please select at least one teacher to export PDFs.');
      return;
    }

    const zip = new JSZip();

    for (const teacherId of selectedTeacherIds) {
      await fetchAndExportPDF(teacherId, zip);
    }

    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'teacher_attendance_records.zip'); // Provide the ZIP file for download
    });

    alert('ZIP file generated with all PDFs for selected teachers.');
  };

  const handleSelectRecord = (recordId, isSelected) => {
    setSelectedRecords((prevSelected) =>
      isSelected
        ? [...prevSelected, recordId]
        : prevSelected.filter((id) => id != recordId)
    );
  };

  const handleBatchVerify = async () => {
    if (selectedRecords.length === 0) {
      alert('Please select at least one record to verify.');
      return;
    }

    // Check if all selected records have a status of "PENDING"
    const invalidRecords = selectedRecords.filter(
      (recordId) =>
        records.find((record) => record.id === recordId)?.status !==
        'PENDING'
    );

    if (invalidRecords.length > 0) {
      alert('All selected records must have a status of "PENDING".');
      return;
    }

    try {
      const response =
        await schoolServiceAdminInstance.verifyMultipleAttendanceRecords(
          selectedRecords
        );
      // Update state based on response
      const updatedRecords = records.map((record) =>
        selectedRecords.includes(record.id)
          ? { ...record, status: 'VERIFIED' }
          : record
      );
      setRecords(updatedRecords);
      setSelectedRecords([]);
      alert('Selected records verified successfully.');
    } catch (err) {
      alert('Failed to verify selected records.');
    }
  };

  const handleBatchDelete = async () => {
    if (selectedRecords.length === 0) {
      alert('Please select at least one record to delete.');
      return;
    }
    if (
      !window.confirm(
        'Are you sure you want to delete these attendance records?'
      )
    ) {
      return;
    }

    try {
      await schoolServiceAdminInstance.deleteMultipleAttendanceRecords(
        selectedRecords
      );
      const updatedRecords = records.filter(
        (record) => !selectedRecords.includes(record.id)
      );
      setRecords(updatedRecords);
      setSelectedRecords([]);
      alert('Selected records deleted successfully.');
    } catch (err) {
      alert('Failed to delete selected records.');
    }
  };

  const handleFetchRecords = async () => {
    setError('');
    if (!startDate || !endDate) {
      setError('Please select both start and end dates.');
      return;
    }

    try {
      const response =
        await schoolServiceAdminInstance.getTeacherAttendanceRecordOnDateRange(
          startDate,
          endDate
        );
      setRecords(response.data);
    } catch (err) {
      setError(
        'Failed to fetch attendance records. Please try again.'
      );
    }
  };

  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    setRecords([]);
    setError('');
  };

  const handleVerifyStatus = async (recordId) => {
    try {
      const response =
        await schoolServiceAdminInstance.verifyAttendanceRecord(
          recordId
        );
      const updatedRecord = response.data;

      // Update the record in the state
      const updatedRecords = records.map((record) =>
        record.id === recordId
          ? { ...record, status: updatedRecord.status }
          : record
      );
      setRecords(updatedRecords);
      alert('Attendance record verified successfully.');
    } catch (error) {
      console.error(error);
      alert('Failed to verify attendance record. Please try again.');
    }
  };

  const handleDeleteRecord = async (recordId) => {
    if (
      !window.confirm(
        'Are you sure you want to delete this attendance record?'
      )
    ) {
      return;
    }

    try {
      await schoolServiceAdminInstance.deleteAttendanceRecord(
        recordId
      );

      // Remove the deleted record from the state
      const updatedRecords = records.filter(
        (record) => record.id !== recordId
      );
      setRecords(updatedRecords);
      alert('Attendance record deleted successfully.');
    } catch (error) {
      console.error(error);
      alert('Failed to delete attendance record. Please try again.');
    }
  };

  return (
    <div className="container mx-auto my-8">
      <AdminDisplayTeacherAttendanceCheck />

      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />

      <h1 className="md:text-2xl text-2xl text-center font-semibold text-cyan-900 py-4">
        Verify/Delete Attendance Records by Date Range
      </h1>
      <div className="inline-flex items-center justify-center w-full">
        <button
          onClick={() =>
            setIsFetchVerifyDeleteRecordsVisible(
              !isFetchVerifyDeleteRecordsVisible
            )
          }
          className="rounded bg-cyan-700 text-white my-3 px-6 py-2 font-semibold hover:bg-cyan-800"
        >
          {isFetchVerifyDeleteRecordsVisible ? 'Hide' : 'Show'}
        </button>
      </div>
      {isFetchVerifyDeleteRecordsVisible && (
        <div className="w-full flex justify-center">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleFetchRecords();
            }}
            className="text-center"
          >
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-60 block px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-60 block px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 mt-2"
            />
            <button
              type="submit"
              className="rounded text-white p-3 my-2 mx-1 font-semibold bg-blue-600 hover:bg-blue-700"
            >
              Fetch
            </button>
            <button
              type="button"
              onClick={handleReset}
              className="rounded bg-gray-600 p-3 my-2 mx-1 text-white font-semibold hover:bg-gray-700"
            >
              Clear and Hide
            </button>
          </form>
        </div>
      )}

      {isFetchVerifyDeleteRecordsVisible && error && (
        <div className="text-red-500 text-center mt-4">{error}</div>
      )}

      {isFetchVerifyDeleteRecordsVisible && records.length > 0 && (
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          <table className="min-w-full border rounded overflow-hidden">
            <thead className="bg-gray-800 text-white text-sm md:text-base lg:text-lg">
              <tr>
                <th className="py-2">Select</th>
                <th className="py-2">Date</th>
                <th className="py-2">Teacher ID</th>
                <th className="py-2">Teacher Name</th>
                <th className="py-2">Presence</th>
                <th className="py-2">Alt Teacher ID</th>
                <th className="py-2">Alt Teacher Name</th>
                <th className="py-2">Class</th>
                <th className="py-2">Status</th>
                <th className="py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {records.map((record, index) => (
                <tr className="border-b text-center" key={index}>
                  <td>
                    <div className="flex justify-center items-center">
                      <input
                        type="checkbox"
                        checked={selectedRecords.includes(record.id)} // Control the checkbox state
                        id={`checkbox-${record.id}`}
                        onChange={(e) =>
                          handleSelectRecord(
                            record.id,
                            e.target.checked
                          )
                        }
                        className={`w-5 h-5 rounded border-gray-300 focus:ring-2 text-blue-600 focus:ring-blue-500`}
                      />
                    </div>
                  </td>

                  <td className="py-2">{record.attendanceDate}</td>
                  <td className="py-2">{record.primaryTeacherId}</td>
                  <td className="py-2">
                    {record.primaryTeacherName}
                  </td>
                  <td className="py-2">
                    {record.wasPresent ? 'Present' : 'Absent'}
                  </td>
                  <td className="py-2">
                    {record.alternativeTeacherId || 'N/A'}
                  </td>
                  <td className="py-2">
                    {record.alternativeTeacherName || 'N/A'}
                  </td>
                  <td className="py-2">{record.classAbbrev}</td>
                  <td
                    className={`py-2 font-semibold ${
                      record.status === 'PENDING'
                        ? 'text-yellow-600'
                        : record.status === 'NOT_TAKEN_YET'
                        ? 'text-gray-500'
                        : 'text-green-600'
                    }`}
                  >
                    {record.status}
                  </td>
                  <td className="py-2">
                    {record.status === 'PENDING' && (
                      <button
                        onClick={() => handleVerifyStatus(record.id)}
                        className="rounded text-white px-4 py-2 font-semibold bg-green-700 hover:bg-green-800"
                      >
                        Verify
                      </button>
                    )}
                    <button
                      onClick={() => handleDeleteRecord(record.id)}
                      className="ml-2 rounded text-white px-4 py-2 font-semibold bg-red-600 hover:bg-red-700"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isFetchVerifyDeleteRecordsVisible && (
        <div className="my-4 flex justify-center">
          <button
            onClick={handleBatchVerify}
            disabled={selectedRecords.length === 0}
            className={`rounded text-white px-4 py-2 font-semibold bg-green-700 hover:bg-green-800 ${
              selectedRecords.length === 0
                ? 'opacity-50 cursor-not-allowed'
                : ''
            }`}
          >
            Verify Selected
          </button>
          <button
            onClick={handleBatchDelete}
            disabled={selectedRecords.length === 0}
            className={`ml-2 rounded text-white px-4 py-2 font-semibold bg-red-600 hover:bg-red-700 ${
              selectedRecords.length === 0
                ? 'opacity-50 cursor-not-allowed'
                : ''
            }`}
          >
            Delete Selected
          </button>
        </div>
      )}
      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />

      <h1 className="md:text-2xl text-2xl text-center font-semibold text-cyan-900 py-4">
        Attendance Records Per Teacher by Date Range
      </h1>
      <div className="inline-flex items-center justify-center w-full">
        <button
          onClick={() =>
            setIsAttendanceRecordsPerTeacherVisible(
              !isAttendanceRecordsPerTeacherVisible
            )
          }
          className="rounded bg-cyan-700 text-white my-3 px-6 py-2 font-semibold hover:bg-cyan-800"
        >
          {isAttendanceRecordsPerTeacherVisible ? 'Hide' : 'Show'}
        </button>
      </div>
      {isAttendanceRecordsPerTeacherVisible && (
        <div className="w-full flex justify-center">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleFetchAttendanceRecords();
            }}
            className="text-center"
          >
            <div className="flex w-full my-2">
              <input
                id="select-all-teachers"
                type="checkbox"
                className="appearance-none h-7 w-7 inline-flex items-center justify-center border border-gray-300 rounded-md checked:bg-green-600 checked:border-green-600 focus:outline-none cursor-pointer
              checked:before:content-['✔'] checked:before:block checked:before:text-white checked:before:text-xl px-3 py-2"
                checked={selectAllTeachers}
                onChange={(e) => {
                  setSelectAllTeachers(e.target.checked);
                  if (e.target.checked) setSelectedTeacherId('');
                }}
              />
              <label
                htmlFor="select-all-teachers"
                className="ml-2 text-gray-700 font-medium cursor-pointer"
              >
                Select All Teachers
              </label>
            </div>
            <select
              value={selectedTeacherId}
              onChange={(e) => setSelectedTeacherId(e.target.value)}
              className="w-60 block px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              disabled={selectAllTeachers}
            >
              <option value="">Select Teacher</option>
              {teacherList.map((teacher) => (
                <option key={teacher.id} value={teacher.id}>
                  {teacher.firstName} {teacher.lastName} |{' '}
                  {teacher.classAbbrev}
                </option>
              ))}
            </select>

            <input
              type="date"
              value={attendanceStartDate}
              onChange={(e) => setAttendanceStartDate(e.target.value)}
              className="w-60 block px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 mt-2"
            />
            <input
              type="date"
              value={attendanceEndDate}
              onChange={(e) => setAttendanceEndDate(e.target.value)}
              className="w-60 block px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 mt-2"
            />
            {/* Beautiful Checkbox */}
            <div className="flex items-center justify-center mt-4">
              <input
                id="include-alternative"
                type="checkbox"
                className="appearance-none h-7 w-7 inline-flex items-center justify-center border border-gray-300 rounded-md checked:bg-green-600 checked:border-green-600 focus:outline-none cursor-pointer
               checked:before:content-['✔'] checked:before:block checked:before:text-white checked:before:text-xl px-3 py-2"
                onChange={(e) =>
                  setIncludeAlternative(e.target.checked)
                }
              />
              <label
                htmlFor="include-alternative"
                className="ml-2 text-gray-700 font-medium cursor-pointer"
              >
                Include Records as Alternative Teacher
              </label>
            </div>
            <button
              type="submit"
              className="rounded text-white p-3 my-2 mx-1 font-semibold bg-blue-600 hover:bg-blue-700"
            >
              Fetch Records
            </button>
            <button
              type="button"
              onClick={() => {
                setSelectedTeacherId('');
                setAttendanceStartDate('');
                setAttendanceEndDate('');
                setAttendanceRecords([]);
                setAttendanceRecordsError('');
              }}
              className="rounded bg-gray-600 p-3 my-2 mx-1 text-white font-semibold hover:bg-gray-700"
            >
              Clear
            </button>
          </form>
        </div>
      )}

      {isAttendanceRecordsPerTeacherVisible &&
        attendanceRecordsError && (
          <div className="text-red-500 text-center mt-4">
            {attendanceRecordsError}
          </div>
        )}

      {isAttendanceRecordsPerTeacherVisible &&
        attendanceRecords.length > 0 && (
          <div className="my-8">
            <table className="min-w-full border rounded overflow-hidden">
              <thead className="bg-gray-800 text-white text-sm">
                <tr>
                  <th className="py-2">Date</th>
                  <th className="py-2">Teacher ID</th>
                  <th className="py-2">Teacher Name</th>
                  <th className="py-2">Presence</th>
                  <th className="py-2">Alt Teacher ID</th>
                  <th className="py-2">Alt Teacher Name</th>
                  <th className="py-2">Class</th>
                  <th className="py-2">Status</th>
                </tr>
              </thead>
              <tbody>
                {attendanceRecords.map((record, index) => {
                  const isIdChanged =
                    index > 0 &&
                    attendanceRecords[index].primaryTeacherId !==
                      attendanceRecords[index - 1].primaryTeacherId;

                  return (
                    <React.Fragment key={index}>
                      {isIdChanged && (
                        <tr>
                          <td
                            colSpan="8"
                            className="py-2 bg-gray-200"
                          ></td>
                        </tr>
                      )}
                      <tr className="border-b text-center">
                        <td className="py-2">
                          {record.attendanceDate}
                        </td>
                        <td className="py-2">
                          {record.primaryTeacherId}
                        </td>
                        <td className="py-2">
                          {record.primaryTeacherName}
                        </td>
                        <td className="py-2">
                          {record.wasPresent ? 'Present' : 'Absent'}
                        </td>
                        <td className="py-2">
                          {record.alternativeTeacherId || 'N/A'}
                        </td>
                        <td className="py-2">
                          {record.alternativeTeacherName || 'N/A'}
                        </td>
                        <td className="py-2">{record.classAbbrev}</td>
                        <td
                          className={`py-2 font-semibold ${
                            record.status === 'PENDING'
                              ? 'text-yellow-600'
                              : 'text-green-600'
                          }`}
                        >
                          {record.status}
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

      {isAttendanceRecordsPerTeacherVisible && (
        <div className="my-4 flex justify-center">
          <button
            onClick={exportToPDF}
            disabled={attendanceRecords.length === 0}
            className={`rounded bg-green-600 text-white px-4 py-2 font-semibold hover:bg-green-700 mx-2 ${
              attendanceRecords.length === 0
                ? 'opacity-50 cursor-not-allowed'
                : ''
            }`}
          >
            Export to PDF
          </button>
          <button
            //onClick={exportToCSV}
            disabled={attendanceRecords.length === 0}
            className={`rounded bg-green-600 text-white px-4 py-2 font-semibold hover:bg-green-700 mx-2 ${'opacity-50 cursor-not-allowed'}`}
          >
            Export to CSV
          </button>
        </div>
      )}

      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />
      <h1 className="text-2xl text-center font-semibold text-cyan-900 py-4">
        Export Attendance Records Per Teacher Or Choose Multiple
        Teachers
      </h1>
      <div className="inline-flex items-center justify-center w-full">
        <button
          onClick={() =>
            setIsExportRecordsVisible(!isExportRecordsVisible)
          }
          className="rounded bg-cyan-700 text-white px-6 py-2 my-3 font-semibold hover:bg-cyan-800"
        >
          {isExportRecordsVisible ? 'Hide' : 'Show'}
        </button>
      </div>
      {isExportRecordsVisible && (
        <div className="w-full flex justify-center">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleExportForAllSelected();
            }}
            className="text-center"
          >
            <select
              multiple
              value={selectedTeacherIds}
              onChange={(e) =>
                setSelectedTeacherIds(
                  Array.from(e.target.selectedOptions, (option) =>
                    parseInt(option.value)
                  )
                )
              }
              className="w-60 block px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            >
              {teacherList.map((teacher) => (
                <option key={teacher.id} value={teacher.id}>
                  {teacher.firstName} {teacher.lastName} |{' '}
                  {teacher.classAbbrev}
                </option>
              ))}
            </select>

            <input
              type="date"
              value={attendanceStartDate}
              onChange={(e) => setAttendanceStartDate(e.target.value)}
              className="w-60 block px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 mt-2"
            />
            <input
              type="date"
              value={attendanceEndDate}
              onChange={(e) => setAttendanceEndDate(e.target.value)}
              className="w-60 block px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 mt-2"
            />
            <button
              type="button"
              onClick={handleExportForAllSelected}
              className="rounded bg-green-600 text-white px-4 py-2 font-semibold hover:bg-green-700 mt-2"
            >
              Export PDFs for Teachers
            </button>
          </form>
        </div>
      )}

      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />

      <div className="mx-auto text-center">
        <button
          onClick={() => navigate('/schoolsuperadmin')}
          className="rounded text-white px-6 py-2 font-semibold bg-cyan-700 hover:bg-cyan-800"
        >
          Back To Dashboard
        </button>
      </div>
    </div>
  );
};

export default TeachersSAP;
