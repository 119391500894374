import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SchoolServiceAdmin from '../services/SchoolServiceAdmin';
import { levelColors, levelTranslations } from './ConstFuncs';
import AdminDisplayTeacherAttendanceCheck from './AfterLoginPages/AdminDisplayTeacherAttendanceCheck';
import StatisticsAttendanceData from './AfterLoginPages/StatisticsAttendanceData';
import AttendanceTable from './AfterLoginPages/AttendanceTable';

const UpdateStudentGroup = ({ data, setData }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [allStudents, setAllStudents] = useState(null);
  const [allTeachers, setAllTeachers] = useState(null);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [showTransferModal, setShowTransferModal] = useState(false);
  let { teacherId } = useParams();
  const [myStudentsOnDateRange, setMyStudentsOnDateRange] =
    useState(null);

  useEffect(() => {
    if (!data) {
      localStorage.clear();
      navigate('/');
    }

    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [studentsResponse, teachersResponse] = await Promise.all([
        SchoolServiceAdmin.getAllStudentsForTeacher(teacherId),
        SchoolServiceAdmin.getAllTeachers(),
      ]);
      setAllStudents(studentsResponse.data);
      setAllTeachers(
        teachersResponse.data.filter(
          (teacher) => teacher.id !== parseInt(teacherId)
        )
      );
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const startDateRef = useRef();
  const endDateRef = useRef();

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === 'start') {
      setStartDate(value);
    } else if (name === 'end') {
      setEndDate(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const startDate = startDateRef.current.value;
    const endDate = endDateRef.current.value;

    // Validate if dates are provided
    if (!startDate || !endDate) {
      alert('Please select both start and end dates.');
      return; // Stop further execution
    }

    try {
      const response =
        await SchoolServiceAdmin.getMyStudentsOnDateRange(
          startDate,
          endDate,
          teacherId
        );
      setMyStudentsOnDateRange(
        response.data.length <= 0 ? null : response.data
      );
      //console.log('RES: ', response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTransfer = async (newTeacherId) => {
    try {
      await SchoolServiceAdmin.transferStudent(
        selectedStudentId,
        newTeacherId
      );
      setShowTransferModal(false);
      setSelectedStudentId(null);
      fetchData();
    } catch (error) {
      console.log('Error transferring student:', error);
    }
  };

  const clearAbsenceList = (event) => {
    event.preventDefault();

    setStartDate('');
    setEndDate('');
    setMyStudentsOnDateRange(null);
  };

  return (
    <div className="container mx-auto my-8">
      {!loading && (
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          <table className="min-w-full border rounded overflow-hidden">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="py-2 px-4">ID</th>
                <th className="py-2 px-4">First Name</th>
                <th className="py-2 px-4">Last Name</th>
                <th className="py-2 px-4">First Name Arabic</th>
                <th className="py-2 px-4">Last Name Arabic</th>
                <th className="py-2 px-4">Parents Email</th>
                <th className="py-2 px-4">Class Days Of Week</th>
                <th className="py-2 px-4">Student Level</th>
                <th className="py-2 px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {allStudents?.map((myStudent) => (
                <tr
                  className="border-b text-center"
                  key={myStudent.id}
                >
                  <td className="py-2 px-4">{myStudent.id}</td>
                  <td className="py-2 px-4">{myStudent.firstName}</td>
                  <td className="py-2 px-4">{myStudent.lastName}</td>
                  <td className="py-2 px-4">
                    {myStudent.firstNameArabic}
                  </td>
                  <td className="py-2 px-4">
                    {myStudent.lastNameArabic}
                  </td>
                  <td className="py-2 px-4">{myStudent.email}</td>
                  <td className="py-2 px-4">
                    {myStudent.classDaysOfWeek.map((e) => e + ', ')}
                  </td>
                  <td
                    className={`py-2 font-semibold text-gray-300 ${
                      myStudent.level != null
                        ? levelColors[myStudent.level]
                        : 'bg-slate-600'
                    }`}
                  >
                    {myStudent.level != null
                      ? levelTranslations[myStudent.level]
                      : 'لم يتم الاختيار بعد'}
                  </td>
                  <td className="py-2 px-4 flex gap-2 justify-center">
                    <button
                      className="rounded bg-blue-700 hover:bg-blue-800 text-white px-6 py-2 font-semibold"
                      onClick={() => {
                        setSelectedStudentId(myStudent.id);
                        setShowTransferModal(true);
                      }}
                    >
                      Transfer
                    </button>
                    <button
                      className="rounded bg-red-700 hover:bg-red-800 text-white px-6 py-2 font-semibold"
                      onClick={async () => {
                        await SchoolServiceAdmin.deleteStudent(
                          teacherId,
                          myStudent.id
                        );
                        fetchData();
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {showTransferModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 shadow-lg">
            <h3 className="text-xl font-semibold mb-4">
              Select New Teacher
            </h3>
            <div className="overflow-y-auto max-h-64">
              {' '}
              {/* Scrollable container */}
              <ul className="space-y-2">
                {allTeachers?.map((teacher) => (
                  <li key={teacher.id}>
                    <button
                      className="w-full text-left px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded"
                      onClick={() => handleTransfer(teacher.id)}
                    >
                      {teacher.firstName} {teacher.lastName}{' '}
                      {teacher.classAbbrev}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <button
              className="mt-4 px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded"
              onClick={() => setShowTransferModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />
      <h1 className="md:text-2xl text-2xl text-center font-semibold text-cyan-900 py-4">
        Get Absence List For DateRange For This Class:
      </h1>
      <div className="w-full flex justify-center">
        <form onSubmit={handleSubmit} className="text-center">
          <input
            ref={startDateRef}
            type="date"
            name="start"
            value={startDate}
            onChange={handleDateChange}
            className="w-60 block px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          />
          <input
            ref={endDateRef}
            type="date"
            name="end"
            value={endDate}
            onChange={handleDateChange}
            className="w-60 block px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 mt-2"
          />
          <div className="grid grid-cols-2 gap-x-2">
            <button
              type="submit"
              className="rounded bg-blue-600 text-white px-2 py-2 my-2 font-semibold hover:bg-blue-700"
            >
              Fetch
            </button>
            <button
              onClick={clearAbsenceList}
              className="rounded bg-gray-600 text-white px-2 py-2 my-2 font-semibold hover:bg-gray-700"
            >
              Clear and Hide
            </button>
          </div>
        </form>
      </div>

      {myStudentsOnDateRange && (
        <>
          <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
            <AttendanceTable data={myStudentsOnDateRange} />
          </div>
          <h1 className="md:text-2xl text-2xl text-center font-semibold text-cyan-900 ">
            P:Present | H:Homework Done | L:Late | D:Disturb
          </h1>
          <StatisticsAttendanceData data={myStudentsOnDateRange} />
        </>
      )}

      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />

      <div className="mx-auto text-center">
        <button
          onClick={() => {
            navigate('/schoolsuperadmin');
          }}
          className="rounded text-white px-6 py-2 font-semibold bg-cyan-700 hover:bg-cyan-800"
        >
          Back To Dashboard
        </button>
      </div>
    </div>
  );
};

export default UpdateStudentGroup;
