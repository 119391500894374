import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import Login from './components/BeforeLoginPages/Login';

import AboutPage from './components/BeforeLoginPages/AboutPage';
import AnnourNavbar from './components/AnnourNavbar';
import { useState } from 'react';
import School from './components/School';
import SchoolAdmin from './components/SchoolAdmin';
import CreateUser from './components/CreateUser';
import HomePage from './components/BeforeLoginPages/HomePage';
import StudentRegistrationPage from './components/BeforeLoginPages/StudentRegistrationPage';
import ContactInfoPage from './components/BeforeLoginPages/ContactInfoPage';
import CreateStudent from './components/CreateStudent';
import GermanLangSupportCourse from './components/BeforeLoginPages/GermanLangSupportCourse';
import ProgrammingCoursesPage from './components/BeforeLoginPages/ProgrammingCoursesPage';
import UpdateStudentGroup from './components/UpdateStudentGroup';
import SchoolSuperAdmin from './components/SchoolSuperAdmin';
import StudentGroup from './components/StudentGroup';
import StudentsServices from './components/superadmin/StudentsServices';
import TeachersServices from './components/superadmin/TeachersServices';
import HolidaysServices from './components/superadmin/HolidaysServices';
import BooksServices from './components/superadmin/BooksServices';
import TeachersSAP from './components/superadmin/TeachersSAP';

function App() {
  const [data, setData] = useState(null);

  return (
    <BrowserRouter>
      <AnnourNavbar data={data} setData={setData} />
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="*" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactInfoPage />} />
        <Route path="/glsc" element={<GermanLangSupportCourse />} />
        <Route
          path="/programmingcourses"
          element={<ProgrammingCoursesPage />}
        />

        <Route
          path="/studentreg"
          element={<StudentRegistrationPage />}
        />
        <Route path="/login" element={<Login setData={setData} />} />

        <Route
          path="/school"
          element={<School data={data} setData={setData} />}
        />
        <Route
          path="/schooladmin"
          element={<SchoolAdmin data={data} setData={setData} />}
        />
        <Route
          path="/schoolsuperadmin"
          element={<SchoolSuperAdmin data={data} setData={setData} />}
        />
        <Route
          path="/superadmin/createuser"
          element={<CreateUser data={data} setData={setData} />}
        />
        <Route
          path="/superadmin/updatestudentgroup/:teacherId"
          element={
            <UpdateStudentGroup data={data} setData={setData} />
          }
        />
        <Route
          path="/admin/getstudentgroup/:teacherId"
          element={<StudentGroup data={data} setData={setData} />}
        />

        <Route
          path="/superadmin/createstudent"
          element={<CreateStudent data={data} setData={setData} />}
        />
        <Route
          path="/superadmin/students-services"
          element={<StudentsServices />}
        />
        <Route
          path="/superadmin/teachers-services"
          element={<TeachersServices />}
        />
        <Route
          path="/superadmin/holidays-services"
          element={<HolidaysServices data={data} />}
        />
        <Route
          path="/superadmin/books-services"
          element={<BooksServices data={data} />}
        />
        <Route
          path="/superadmin/teachers-sap-services"
          element={<TeachersSAP data={data} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
