import { request } from '../api/authenticationService';

class BookService {
  // Fetch all books
  getAllBooks() {
    return request('GET', '/admin/books');
  }

  // Fetch a book by its ID
  getBookById(bookId) {
    return request('GET', `/admin/books/${bookId}`);
  }

  // Create a book
  createBook(book) {
    return request('POST', '/superadmin/books', book);
  }

  // Update a book
  updateBook(bookId, book, statusQuery) {
    const url = `/superadmin/books/${bookId}?status=${statusQuery}`;
    return request('PUT', url, book);
  }

  // Delete a book
  deleteBook(bookId) {
    return request('DELETE', `/superadmin/books/${bookId}`);
  }

  // Send book notification to a single student
  sendBookNotification(bookIds, studentId) {
    const queryParams = new URLSearchParams({
      studentId,
      bookIds: bookIds.join(','),
    }).toString();
    return request(
      'POST',
      `/superadmin/books/sendBookNotification?${queryParams}`
    );
  }

  // Send book notifications to all students under a teacher
  sendBookNotificationToAll(bookIds, teacherId) {
    const queryParams = new URLSearchParams({
      teacherId,
      bookIds: bookIds.join(','),
    }).toString();
    return request(
      'POST',
      `/superadmin/books/sendBookNotificationToAll?${queryParams}`
    );
  }

  issueBooksToTeacher(teacherId, issueDetails) {
    return request(
      'POST',
      `/superadmin/books/issueToTeacher/${teacherId}`,
      issueDetails
    );
  }

  // Fetch logs for a specific date range
  getLogs(dateRange) {
    const queryParams = new URLSearchParams({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    }).toString();
    return request('GET', `/superadmin/books/logs?${queryParams}`);
  }
}

// Assign the instance to a variable
const bookServiceInstance = new BookService();
// Export the instance
export default bookServiceInstance;
