import React from 'react';
import { useNavigate } from 'react-router-dom';
import schoolServiceAdminInstance from '../../services/SchoolServiceAdmin';

const TeachersServices = ({ allTeachers, refreshAllTeacher }) => {
  const navigate = useNavigate();

  return (
    <div className="teachers-services container mx-auto my-8">
      <h1 className="text-2xl font-semibold text-center">
        Teachers Services
      </h1>
      <div className="inline-flex items-center justify-center w-full">
        <button
          onClick={() => refreshAllTeacher()}
          className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
        >
          Refresh Teachers List
        </button>
        <button
          onClick={() => navigate('/superadmin/createuser')}
          className="rounded bg-green-700 text-white px-6 py-2 font-semibold hover:bg-green-800 ml-4"
        >
          + Add Teacher
        </button>
      </div>

      <table className="min-w-full border rounded overflow-hidden mt-8">
        <thead className="bg-gray-800 text-white">
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>User Name</th>
            <th>Class</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {allTeachers?.map((teacher) => (
            <tr key={teacher.id} className="border-b text-center">
              <td>{teacher.id}</td>
              <td>{teacher.email}</td>
              <td>{teacher.firstName}</td>
              <td>{teacher.lastName}</td>
              <td>{teacher.username}</td>
              <td>{teacher.classAbbrev}</td>
              <td>
                <button
                  onClick={() =>
                    navigate(
                      `/superadmin/updatestudentgroup/${teacher.id}`
                    )
                  }
                  className="rounded bg-blue-700 text-white px-4 py-2 font-semibold hover:bg-blue-800"
                >
                  Update
                </button>
                <button
                  onClick={async () => {
                    await schoolServiceAdminInstance.deleteTeacher(
                      teacher.id
                    );
                    refreshAllTeacher();
                  }}
                  className="rounded bg-red-700 text-white px-4 py-2 font-semibold hover:bg-red-800 ml-2"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TeachersServices;
