import axios from 'axios';
import { request } from '../api/authenticationService';

class SchoolService {
  getMyStudents() {
    return request('GET', '/students');
  }
  getMyStudentsOnDate(date) {
    return request('GET', `/myStudentsOnDate?date=${date}`);
  }
  updateMyStudentsOnDate(
    wasPresent,
    alternativeTeacherId,
    studentDate
  ) {
    return request(
      'PUT',
      `/updateMyStudentsOnDate/?wasPresent=${wasPresent}${
        alternativeTeacherId
          ? `&alternativeTeacherId=${alternativeTeacherId}`
          : ''
      }`,
      studentDate
    );
  }

  updateLevelToStudent(teacherId, studentId, studentNewLevel) {
    return request(
      'PUT',
      `/updateLevelToStudent/${teacherId}?studentId=${studentId}&level=${studentNewLevel}`
    );
  }

  getAllTeachersInfo() {
    return request('GET', '/allTeachersInfo');
  }
}

// Assign the instance to a variable
const schoolServiceInstance = new SchoolService();
// Export the instance
export default schoolServiceInstance;
