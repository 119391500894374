import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SchoolService from '../services/SchoolService';
import StudentOnDate from './StudentOnDate';
import annourLogo from '../imgs/annour-logo.png';
import { request } from '../api/authenticationService';
import sun from '../imgs/sun00.png';
import StudentLevelPopup from './StudentLevelPopup';
import { levelColors, levelTranslations } from './ConstFuncs';
import AlternativeTeacherDialog from './AfterLoginPages/AlternativeTeacherDialog';

const School = ({ data, setData }) => {
  const dateRef = useRef();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [myStudents, setMyStudents] = useState(null);
  const [myStudentsOnDate, setMyStudentsOnDate] = useState(null);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [todayDate, setTodayDate] = useState({});
  const [myStudentsToday, setMyStudentsToday] = useState(null);
  const [myStudentsTodayPostShow, setMyStudentsTodayPostShow] =
    useState(false);

  const [isTeacherPresent, setIsTeacherPresent] = useState(true); // Whether the teacher was present
  const [
    selectedAlternativeTeacherId,
    setSelectedAlternativeTeacherId,
  ] = useState(''); // Selected alternative teacher

  const [allTeachers, setAllTeachers] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const selectedTeacher = allTeachers?.find(
    (teacher) => teacher.id === selectedAlternativeTeacherId
  );

  useEffect(() => {
    if (!data) {
      localStorage.clear();
      navigate('/');
    }
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await SchoolService.getAllTeachersInfo();
        setAllTeachers(response.data);

        const response2 = await SchoolService.getMyStudents();
        setMyStudents(response2.data);
        const date = new Date();
        setTodayDate({
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        });
        fetchMyStudentsToday(
          date.getDate(),
          date.getMonth() + 1,
          date.getFullYear()
        );
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const fetchMyStudentsToday = async (day, month, year) => {
    // console.log(day + '.' + month + '.' + year);
    try {
      const finalDate =
        year +
        '-' +
        (month < 10 ? '0' + month : month) +
        '-' +
        (day < 10 ? '0' + day : day);
      const response = await SchoolService.getMyStudentsOnDate(
        finalDate
      );
      setMyStudentsToday(
        response.data.length <= 0 ? null : response.data
      );
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const updateMyStudentsToday = async () => {
    if (myStudentsToday.length <= 0) return;
    if (!isTeacherPresent && !selectedAlternativeTeacherId) {
      alert('يرجى اختيار المعلم البديل إذا لم تكن موجودًا.');
      return;
    }

    const attendanceData = {
      wasPresent: isTeacherPresent,
      alternativeTeacherId: isTeacherPresent
        ? null
        : selectedAlternativeTeacherId,

      studentDate: myStudentsToday?.map((myStudentsToday) => ({
        studentId: myStudentsToday.studentEntity.id,
        dateId: myStudentsToday.dateEntity.id,
        present:
          myStudentsToday.present != null
            ? myStudentsToday.present
            : false,
        late:
          myStudentsToday.present && myStudentsToday.late != null
            ? myStudentsToday.late
            : false,
        homeworkDone:
          myStudentsToday.present &&
          myStudentsToday.homeworkDone != null
            ? myStudentsToday.homeworkDone
            : false,
        disturb:
          myStudentsToday.present && myStudentsToday.disturb != null
            ? myStudentsToday.disturb
            : false,
      })),
    };

    try {
      const response = await SchoolService.updateMyStudentsOnDate(
        attendanceData.wasPresent,
        attendanceData.alternativeTeacherId,
        attendanceData.studentDate
      );
      // do some calcs
      const counts = attendanceData.studentDate.reduce(
        (acc, student) => {
          acc.present += student.present ? 1 : 0;
          acc.late += student.late ? 1 : 0;
          acc.homeworkDone += student.homeworkDone ? 1 : 0;
          return acc;
        },
        { present: 0, late: 0, homeworkDone: 0 }
      );

      setMyStudentsTodayPostShow(counts);
    } catch (error) {
      console.log(error);
    }
  };

  const changePresenceStatusToday = (index) => {
    const updatedMyStudentsToday = [...myStudentsToday];

    if (updatedMyStudentsToday[index].present) {
      updatedMyStudentsToday[index].late = false;
      updatedMyStudentsToday[index].homeworkDone = false;
      updatedMyStudentsToday[index].disturb = false;
    }

    // Toggle the present property for the specified index
    updatedMyStudentsToday[index].present =
      !updatedMyStudentsToday[index].present;

    // Update the state with the new array
    setMyStudentsToday(updatedMyStudentsToday);
  };
  const changeLateStatusToday = (index) => {
    const updatedMyStudentsToday = [...myStudentsToday];
    // Toggle the present property for the specified index
    updatedMyStudentsToday[index].late =
      !updatedMyStudentsToday[index].late;

    // Update the state with the new array
    setMyStudentsToday(updatedMyStudentsToday);
  };
  const changeHomeworkDoneStatusToday = (index) => {
    const updatedMyStudentsToday = [...myStudentsToday];

    // Toggle the present property for the specified index
    updatedMyStudentsToday[index].homeworkDone =
      !updatedMyStudentsToday[index].homeworkDone;

    // Update the state with the new array
    setMyStudentsToday(updatedMyStudentsToday);
  };
  const changeDisturbStatusToday = (index) => {
    const updatedMyStudentsToday = [...myStudentsToday];

    // Toggle the present property for the specified index
    updatedMyStudentsToday[index].disturb =
      !updatedMyStudentsToday[index].disturb;

    // Update the state with the new array
    setMyStudentsToday(updatedMyStudentsToday);
  };

  const [
    fetchMyStudentsOnDateLoading,
    setFetchMyStudentsOnDateLoading,
  ] = useState(false);
  const [fetchMyStudentsOnDateMsg, setFetchMyStudentsOnDateMsg] =
    useState('');

  const fetchMyStudentsOnDate = async () => {
    // console.log(dateRef.current.value);
    if (dateRef.current.value.length <= 0) return;
    setFetchMyStudentsOnDateLoading(true);
    try {
      const response = await SchoolService.getMyStudentsOnDate(
        dateRef.current.value
      );
      setMyStudentsOnDate(
        response.data.length <= 0 ? null : response.data
      );
      setFetchMyStudentsOnDateMsg(
        response.data.length <= 0
          ? 'لايوجد قائمة حضور لهذا التاريخ'
          : response.data
      );
      // console.log(response);
    } catch (error) {
      console.log(error);
      setMyStudentsOnDate(null);
      setFetchMyStudentsOnDateMsg('');
    } finally {
      setFetchMyStudentsOnDateLoading(false);
    }
  };

  const updateMyStudentsOnDate = async () => {
    if (myStudentsOnDate.length <= 0) return;
    if (!isTeacherPresent && !selectedAlternativeTeacherId) {
      alert('يرجى اختيار المعلم البديل إذا لم تكن موجودًا.');
      return;
    }

    const attendanceData = {
      wasPresent: isTeacherPresent,
      alternativeTeacherId: isTeacherPresent
        ? null
        : selectedAlternativeTeacherId,

      studentDate: myStudentsOnDate?.map((myStudentsOnDate) => ({
        studentId: myStudentsOnDate.studentEntity.id,
        dateId: myStudentsOnDate.dateEntity.id,
        present:
          myStudentsOnDate.present != null
            ? myStudentsOnDate.present
            : false,
        late:
          myStudentsOnDate.present && myStudentsOnDate.late != null
            ? myStudentsOnDate.late
            : false,
        homeworkDone:
          myStudentsOnDate.present &&
          myStudentsOnDate.homeworkDone != null
            ? myStudentsOnDate.homeworkDone
            : false,
        disturb:
          myStudentsOnDate.present && myStudentsOnDate.disturb != null
            ? myStudentsOnDate.disturb
            : false,
      })),
    };
    try {
      const response = await SchoolService.updateMyStudentsOnDate(
        attendanceData.wasPresent,
        attendanceData.alternativeTeacherId,
        attendanceData.studentDate
      );
      setMyStudentsOnDate(null);
    } catch (error) {
      console.log(error);
    }
  };

  const changePresenceStatus = (index) => {
    const updatedMyStudentsOnDate = [...myStudentsOnDate];

    if (updatedMyStudentsOnDate[index].present) {
      updatedMyStudentsOnDate[index].late = false;
      updatedMyStudentsOnDate[index].homeworkDone = false;
      updatedMyStudentsOnDate[index].disturb = false;
    }

    // Toggle the present property for the specified index
    updatedMyStudentsOnDate[index].present =
      !updatedMyStudentsOnDate[index].present;

    // Update the state with the new array
    setMyStudentsOnDate(updatedMyStudentsOnDate);
  };
  const changeLateStatus = (index) => {
    const updatedMyStudentsOnDate = [...myStudentsOnDate];

    // Toggle the present property for the specified index
    updatedMyStudentsOnDate[index].late =
      !updatedMyStudentsOnDate[index].late;

    // Update the state with the new array
    setMyStudentsOnDate(updatedMyStudentsOnDate);
  };
  const changeHomeworkDoneStatus = (index) => {
    const updatedMyStudentsOnDate = [...myStudentsOnDate];

    // Toggle the present property for the specified index
    updatedMyStudentsOnDate[index].homeworkDone =
      !updatedMyStudentsOnDate[index].homeworkDone;

    // Update the state with the new array
    setMyStudentsOnDate(updatedMyStudentsOnDate);
  };
  const changeDisturbStatus = (index) => {
    const updatedMyStudentsOnDate = [...myStudentsOnDate];

    // Toggle the present property for the specified index
    updatedMyStudentsOnDate[index].disturb =
      !updatedMyStudentsOnDate[index].disturb;

    // Update the state with the new array
    setMyStudentsOnDate(updatedMyStudentsOnDate);
  };

  const toggleTableVisibility = () => {
    setIsTableVisible(!isTableVisible);
  };

  const [popupStates, setPopupStates] = useState({});

  const togglePopup = (studentId) => {
    setPopupStates((prevStates) => ({
      ...prevStates,
      [studentId]: !prevStates[studentId],
    }));
  };

  return (
    <>
      <div className="container mx-auto my-8">
        <div className="inline-flex items-center justify-center w-full">
          <img
            alt="sun img"
            src={sun}
            className="rotate-sun h-14 w-14"
          />
        </div>

        <div className="inline-flex items-center justify-center w-full">
          <hr className="md:w-1/2 h-1 my-8 bg-cyan-800 border-0 rounded " />
          <div className="absolute  -translate-x-1/2 bg-white left-1/2 ">
            <h1 className="md:text-2xl text-2xl text-center font-semibold text-cyan-900 ">
              قائمة الحضور لليوم
            </h1>
            <h1 className="md:text-2xl text-2xl text-center font-semibold text-cyan-900 ">
              {todayDate.day +
                '.' +
                todayDate.month +
                '.' +
                todayDate.year}
            </h1>
          </div>
        </div>
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4  justify-center">
          {!myStudentsTodayPostShow && myStudentsToday ? (
            myStudentsToday?.map((myStudentsToday, index) => (
              <StudentOnDate
                key={myStudentsToday?.studentEntity?.id}
                myStudentsOnDate={myStudentsToday}
                changePresenceStatus={changePresenceStatusToday}
                index={index}
                changeLateStatus={changeLateStatusToday}
                changeHomeworkDoneStatus={
                  changeHomeworkDoneStatusToday
                }
                changeDisturbStatus={changeDisturbStatusToday}
              />
            ))
          ) : !myStudentsTodayPostShow ? (
            <div className="inline-flex items-center justify-center w-full">
              <h4 className="md:text-2xl text-lg text-center text-black-900 ">
                لايوجد قائمة حضور لهذا التاريخ
              </h4>
            </div>
          ) : (
            <></>
          )}

          {myStudentsToday && myStudentsTodayPostShow && (
            <div>
              <h1 className="md:text-2xl text-2xl text-center font-semibold text-cyan-900 ">
                {myStudentsTodayPostShow.present + ' =عدد الحضور'}
              </h1>
              <h1 className="md:text-2xl text-2xl text-center font-semibold text-cyan-900 ">
                {isTeacherPresent
                  ? `كنت هنا`
                  : ` ${
                      allTeachers.find(
                        (teacher) =>
                          teacher.id == selectedAlternativeTeacherId
                      )?.lastNameArabic
                    } ${
                      allTeachers.find(
                        (teacher) =>
                          teacher.id == selectedAlternativeTeacherId
                      )?.firstNameArabic
                    } :المعلم البديل `}
              </h1>
              <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
                <table className="min-w-full border rounded overflow-hidden">
                  <thead className="bg-gray-800 text-white">
                    <tr>
                      <th className="py-2 px-4">إزعاج</th>
                      <th className="py-2 px-4">تأخير</th>
                      <th className="py-2 px-4">
                        عمل الواجب المدرسي
                      </th>
                      <th className="py-2 px-4">اسم العائلة</th>
                      <th className="py-2 px-4">الاسم الأول</th>
                    </tr>
                  </thead>
                  <tbody>
                    {myStudentsToday?.map(
                      (myStudentsToday) =>
                        myStudentsToday.present && (
                          <tr
                            className="border-b text-center"
                            key={myStudentsToday.studentEntity.id}
                          >
                            <td className="py-2 px-4 font-semibold">
                              {myStudentsToday.disturb ? 'نعم' : 'لا'}
                            </td>
                            <td className="py-2 px-4 font-semibold">
                              {myStudentsToday.late ? 'نعم' : 'لا'}
                            </td>
                            <td
                              className={`py-2 px-4 font-semibold ${
                                myStudentsToday.homeworkDone
                                  ? 'text-green-900'
                                  : 'text-red-900'
                              }`}
                            >
                              {myStudentsToday.homeworkDone
                                ? 'نعم'
                                : 'لا'}
                            </td>
                            <td className="py-2 px-4">
                              {
                                myStudentsToday.studentEntity
                                  .lastNameArabic
                              }
                            </td>
                            <td className="py-2 px-4">
                              {
                                myStudentsToday.studentEntity
                                  .firstNameArabic
                              }
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        {myStudentsToday && !myStudentsTodayPostShow && (
          <>
            <div className="flex flex-col items-center">
              <div className="flex items-center mt-10 mb-8">
                <label
                  htmlFor="teacher-present"
                  className="flex items-center space-x-3 cursor-pointer"
                >
                  <input
                    id="teacher-present"
                    type="checkbox"
                    className="appearance-none h-12 w-12 border border-gray-300 rounded-md checked:bg-green-600 checked:border-green-600 focus:outline-none  cursor-pointer
                    checked:before:content-['✔'] checked:before:block checked:before:text-white checked:before:text-4xl checked:before:flex checked:before:justify-center checked:before:items-center"
                    checked={isTeacherPresent}
                    onChange={() =>
                      setIsTeacherPresent(!isTeacherPresent)
                    }
                  />
                  <span className="ml-4 font-semibold text-xl">
                    :كنت هنا
                  </span>
                </label>
              </div>

              {!isTeacherPresent && (
                <button
                  onClick={() => setIsDialogOpen(true)}
                  className="rounded bg-blue-500 text-2xl text-white px-4 py-2"
                >
                  {selectedTeacher
                    ? `المعلم البديل:  ${selectedTeacher.firstName} ${selectedTeacher.lastName}`
                    : 'اختر معلما بديلا'}{' '}
                </button>
              )}

              <AlternativeTeacherDialog
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                allTeachers={allTeachers}
                selectedAlternativeTeacherId={
                  selectedAlternativeTeacherId
                }
                setSelectedAlternativeTeacherId={
                  setSelectedAlternativeTeacherId
                }
              />
            </div>

            <div className="flex justify-center space-x-4">
              <button
                onClick={updateMyStudentsToday}
                className="hover:bg-green-900 rounded text-2xl bg-green-700 text-white font-semibold px-10 py-5 mt-4"
              >
                إرسال
              </button>
            </div>
          </>
        )}
        {myStudentsTodayPostShow && (
          <div className="flex justify-center space-x-4">
            <button
              onClick={() => {
                setMyStudentsTodayPostShow(false);
              }}
              className="hover:bg-green-900 rounded text-2xl bg-green-700 text-white font-semibold px-10 py-5 mt-4"
            >
              تعديل
            </button>
          </div>
        )}

        <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />
        <div className="inline-flex items-center justify-center w-full">
          <hr className="w-96 h-1 my-8 bg-cyan-800 border-0 rounded " />
          <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 ">
            <button
              onClick={toggleTableVisibility}
              className="rounded bg-cyan-700 text-xl text-white px-6 py-2 font-semibold hover:bg-cyan-800"
            >
              {isTableVisible ? 'إخفاء الأسماء' : 'إظهار الأسماء'}
            </button>
          </div>
        </div>

        {!loading && isTableVisible && (
          <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
            <table className="min-w-full border rounded overflow-hidden">
              <thead className="bg-gray-800 text-white">
                <tr>
                  <th className="py-2 px-4">المستوى الشهري</th>
                  <th className="py-2 px-4">اسم العائلة</th>
                  <th className="py-2 px-4">الاسم الأول</th>
                </tr>
              </thead>
              <tbody>
                {myStudents?.map((myStudent) => (
                  <tr
                    className="border-b text-center"
                    key={myStudent.id}
                  >
                    <td className="py-2 px-4">
                      <button
                        className={`text-base rounded ${
                          myStudent.level != null
                            ? levelColors[myStudent.level]
                            : 'bg-slate-600'
                        } text-white font-semibold px-2 py-2`}
                        onClick={() => togglePopup(myStudent.id)}
                      >
                        {myStudent.level != null
                          ? levelTranslations[myStudent.level]
                          : 'اضغط هنا للاختيار'}
                      </button>
                      <StudentLevelPopup
                        isOpen={popupStates[myStudent.id] || false}
                        togglePopup={() => togglePopup(myStudent.id)}
                        level={myStudent.level}
                        student={myStudent}
                        teacherId={data.id}
                      />
                    </td>
                    <td className="py-2 px-4">
                      {myStudent.lastNameArabic}
                    </td>
                    <td className="py-2 px-4">
                      {myStudent.firstNameArabic}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />
        <div className="inline-flex items-center justify-center w-full">
          <hr className="md:w-1/2 h-1 my-8 bg-cyan-800 border-0 rounded " />
          <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 ">
            <h1 className="md:text-2xl text-lg text-center font-semibold text-cyan-900 ">
              تعديل قائمة الحضور لتاريخ
            </h1>
          </div>
        </div>

        <div className="lg:flex-wrap lg:flex md:flex-1 my-4 flex justify-center space-x-4 items-baseline">
          <input type="date" ref={dateRef} />
          <button
            onClick={fetchMyStudentsOnDate}
            className="hover:bg-green-900 text-xl rounded bg-green-700 text-white font-semibold px-3 py-3 mt-4"
          >
            جلب
          </button>
        </div>
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          {fetchMyStudentsOnDateLoading ? (
            <div className="inline-flex items-center justify-center w-full">
              <h4 className="md:text-2xl text-lg text-center text-black-900 ">
                جاري جلب البيانات
              </h4>
            </div>
          ) : myStudentsOnDate ? (
            myStudentsOnDate?.map((myStudentsOnDate, index) => (
              <StudentOnDate
                key={myStudentsOnDate?.studentEntity?.id}
                myStudentsOnDate={myStudentsOnDate}
                changePresenceStatus={changePresenceStatus}
                index={index}
                changeLateStatus={changeLateStatus}
                changeHomeworkDoneStatus={changeHomeworkDoneStatus}
                changeDisturbStatus={changeDisturbStatus}
              />
            ))
          ) : (
            <div className="inline-flex items-center justify-center w-full">
              <h4 className="md:text-2xl text-lg text-center text-black-900 ">
                {fetchMyStudentsOnDateMsg}
              </h4>
            </div>
          )}
        </div>
        {myStudentsOnDate && (
          <>
            <div className="flex flex-col items-center">
              <div className="flex items-center mt-10 mb-8">
                <label
                  htmlFor="teacher-present"
                  className="flex items-center space-x-3 cursor-pointer"
                >
                  <input
                    id="teacher-present"
                    type="checkbox"
                    className="appearance-none h-12 w-12 border border-gray-300 rounded-md checked:bg-green-600 checked:border-green-600 focus:outline-none  cursor-pointer
                    checked:before:content-['✔'] checked:before:block checked:before:text-white checked:before:text-4xl checked:before:flex checked:before:justify-center checked:before:items-center"
                    checked={isTeacherPresent}
                    onChange={() =>
                      setIsTeacherPresent(!isTeacherPresent)
                    }
                  />
                  <span className="ml-4 font-semibold text-xl">
                    :كنت هنا
                  </span>
                </label>
              </div>

              {!isTeacherPresent && (
                <button
                  onClick={() => setIsDialogOpen(true)}
                  className="rounded bg-blue-500 text-2xl text-white px-4 py-2"
                >
                  {selectedTeacher
                    ? `المعلم البديل:  ${selectedTeacher.firstName} ${selectedTeacher.lastName}`
                    : 'اختر معلما بديلا'}{' '}
                </button>
              )}

              <AlternativeTeacherDialog
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                allTeachers={allTeachers}
                selectedAlternativeTeacherId={
                  selectedAlternativeTeacherId
                }
                setSelectedAlternativeTeacherId={
                  setSelectedAlternativeTeacherId
                }
              />
            </div>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => {
                  setFetchMyStudentsOnDateMsg('تم التعديل بنجاح');
                  updateMyStudentsOnDate();
                }}
                className="hover:bg-green-900 text-xl rounded bg-green-700 text-white font-semibold px-5 py-3 mt-4"
              >
                تعديل
              </button>
              <button
                onClick={() => {
                  setMyStudentsOnDate(null);
                  setFetchMyStudentsOnDateMsg('');
                }}
                className="hover:bg-red-900 text-xl rounded bg-red-700 text-white font-semibold px-5 py-3 mt-4"
              >
                إلغاء
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default School;
