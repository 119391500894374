import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const StudentsServices = ({
  allStudents,
  toggleStudentsTableVisibility,
  isStudentsTableVisible,
}) => {
  const navigate = useNavigate();

  return (
    <div className="students-services container mx-auto my-8">
      <h1 className="text-2xl font-semibold text-center">
        Students Services
      </h1>
      <div className="inline-flex items-center justify-center w-full">
        <button
          onClick={toggleStudentsTableVisibility}
          className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
        >
          {isStudentsTableVisible
            ? 'Hide All Students'
            : 'Show All Students'}
        </button>
        <button
          onClick={() => navigate('/superadmin/createstudent')}
          className="rounded bg-green-700 text-white px-6 py-2 font-semibold hover:bg-green-800 ml-4"
        >
          + Add Student
        </button>
      </div>

      {!isStudentsTableVisible ? null : (
        <table className="min-w-full border rounded overflow-hidden mt-8">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th>ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Class Days</th>
              <th>Teacher</th>
            </tr>
          </thead>
          <tbody>
            {allStudents?.map((student) => (
              <tr key={student.id} className="border-b text-center">
                <td>{student.id}</td>
                <td>{student.firstName}</td>
                <td>{student.lastName}</td>
                <td>{student.email}</td>
                <td>{student.classDaysOfWeek.join(', ')}</td>
                <td>{student.teacherUserName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default StudentsServices;
