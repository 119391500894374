import React, { useState } from 'react';
import Select from 'react-select';
import BookService from '../../services/BookService';

const TeacherClass = ({ teacher, books }) => {
  const [selectedBooksForClass, setSelectedBooksForClass] = useState(
    []
  );
  const [selectedBooksForStudents, setSelectedBooksForStudents] =
    useState({});
  const [isExpanded, setIsExpanded] = useState(false); // State to manage collapsibility

  const bookOptions = books.map((book) => ({
    value: book.id,
    label: book.title,
  }));

  const handleStudentBookSelection = (studentId, selectedOptions) => {
    setSelectedBooksForStudents((prevState) => ({
      ...prevState,
      [studentId]: selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [],
    }));
  };

  const handleNotifyAllStudents = async () => {
    try {
      if (selectedBooksForClass.length === 0) {
        alert('Please select at least one book.');
        return;
      }
      await BookService.sendBookNotificationToAll(
        selectedBooksForClass,
        teacher.id
      );
      alert('Notifications sent to all students in the class.');
    } catch (error) {
      console.error('Error notifying all students:', error);
    }
  };

  const handleNotifyStudent = async (studentId) => {
    try {
      const bookIds = selectedBooksForStudents[studentId] || [];
      if (bookIds.length === 0) {
        alert('Please select at least one book.');
        return;
      }
      await BookService.sendBookNotification(bookIds, studentId);
      alert('Notification sent to the student.');
    } catch (error) {
      console.error('Error notifying student:', error);
    }
  };

  return (
    <div className="my-4 border p-4 rounded shadow">
      {/* Header with toggle button */}
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold text-gray-700">
          Class of {teacher.firstName} {teacher.lastName} |{' '}
          {teacher.classAbbrev}
        </h2>

        <td className="py-2 px-4 space-x-2">
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="rounded bg-cyan-700 text-white px-6 py-2 font-semibold hover:bg-cyan-800"
          >
            {isExpanded ? 'Collapse' : 'Expand'}
          </button>
        </td>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">
          Select Books for the Class:
        </label>
        <Select
          isMulti
          options={bookOptions}
          onChange={(selectedOptions) =>
            setSelectedBooksForClass(
              selectedOptions
                ? selectedOptions.map((option) => option.value)
                : []
            )
          }
          className="mt-2"
        />
        <button
          onClick={handleNotifyAllStudents}
          className="mt-4 rounded bg-green-700 text-white px-4 py-2 font-semibold hover:bg-green-800"
        >
          Notify All Students
        </button>
      </div>

      {isExpanded && (
        <>
          {/* Content shown when expanded */}

          <h3 className="text-lg font-bold text-gray-600 mb-2">
            Students:
          </h3>
          <table className="min-w-full border rounded overflow-hidden">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="py-2 px-4">ID</th>
                <th className="py-2 px-4">First Name</th>
                <th className="py-2 px-4">Last Name</th>
                <th className="py-2 px-4">Email</th>
                <th className="py-2 px-4">Last Email Sent</th>
                <th className="py-2 px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {teacher.students.map((student) => (
                <tr key={student.id} className="border-b text-center">
                  <td className="py-2 px-4">{student.id}</td>
                  <td className="py-2 px-4">{student.firstName}</td>
                  <td className="py-2 px-4">{student.lastName}</td>
                  <td className="py-2 px-4">{student.email}</td>
                  <td className="py-2 px-4">
                    {student.lastBookEmailSent
                      ? new Date(
                          student.lastBookEmailSent
                        ).toLocaleString()
                      : 'Never'}
                  </td>
                  <td className="py-2 px-4 flex items-center space-x-2">
                    <Select
                      isMulti
                      options={bookOptions}
                      onChange={(selectedOptions) =>
                        handleStudentBookSelection(
                          student.id,
                          selectedOptions
                        )
                      }
                      className="w-60"
                    />
                    <button
                      onClick={() => handleNotifyStudent(student.id)}
                      className="mt-4 rounded bg-green-700 text-white px-4 py-2 font-semibold hover:bg-green-800"
                    >
                      Notify
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default TeacherClass;
