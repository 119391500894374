import { request } from '../api/authenticationService';

class SchoolServiceAdmin {
  getAllStudents() {
    return request('GET', '/admin/allstudents');
  }
  getAllTeachers() {
    return request('GET', '/admin/allteachers');
  }
  getAllArchivedStudents() {
    return request('GET', '/admin/allarchivedstudents');
  }
  getAllHolidays() {
    return request('GET', '/admin/allholidays');
  }

  getMyStudentsOnDateRange(startDate, endDate, teacherId) {
    return request(
      'GET',
      `/admin/myStudentsOnDateRange/${teacherId}?startdate=${startDate}&enddate=${endDate}`
    );
  }

  getTeacherAttendanceCheckOnDateRange(startDate, endDate) {
    return request(
      'GET',
      `/admin/teacherAttendanceCheckOnDateRange/?startdate=${startDate}&enddate=${endDate}`
    );
  }

  getMyStudentsOnDate(date) {
    return request('GET', `/myStudentsOnDate?date=${date}`);
  }
  updateMyStudentsOnDate(studentDate) {
    return request('PUT', `/updateMyStudentsOnDate`, studentDate);
  }

  getAllStudentsForTeacher(teacherId) {
    return request(
      'GET',
      `/superadmin/updatestudentgroup/${teacherId}`
    );
  }
  getAdminAllStudentsForTeacher(teacherId) {
    return request('GET', `/admin/getstudentgroup/${teacherId}`);
  }
  deleteStudent(teacherId, studentId) {
    return request(
      'DELETE',
      `/superadmin/deletestudentfromteacher/${teacherId}?studentId=${studentId}`
    );
  }
  deleteTeacher(teacherId) {
    return request(
      'DELETE',
      `/superadmin/deleteteacher/${teacherId}`
    );
  }

  deleteHoliday(id) {
    return request('DELETE', `/superadmin/deleteholiday/${id}`);
  }

  createNewHoliday(date) {
    return request(
      'POST',
      `/superadmin/createholidaydate?date=${date}`
    );
  }

  getEmailSendingStatus() {
    return request('GET', '/superadmin/getSendEmailFlag');
  }

  updateSendEmailFlag(isChecked) {
    return request(
      'POST',
      `/superadmin/updateSendEmailFlag?sendEmail=${isChecked}`
    );
  }

  createNewAbsenceListForTeacherOnDate(teacherId, date) {
    return request(
      'POST',
      `/superadmin/createAbsenceListForTeacher/${teacherId}?date=${date}`
    );
  }
  deleteAbsenceListForTeacherOnDate(teacherId, date) {
    return request(
      'DELETE',
      `/superadmin/deleteAbsenceListForTeacher/${teacherId}?date=${date}`
    );
  }

  transferStudent(studentId, newTeacherId) {
    return request(
      'POST',
      `/superadmin/transferStudent?studentId=${studentId}&newTeacherId=${newTeacherId}`
    );
  }

  deleteArchivedStudent(studentId) {
    return request(
      'DELETE',
      `/superadmin/deleteArchivedStudent/${studentId}`
    );
  }

  getTeacherAttendanceRecordOnDateRange(startDate, endDate) {
    return request(
      'GET',
      `/attendance/date-range?startDate=${startDate}&endDate=${endDate}`
    );
  }

  /**
   * Verify an attendance record by ID.
   * @param {number} recordId - The ID of the attendance record to verify.
   */
  verifyAttendanceRecord(recordId) {
    return request(
      'PUT',
      `/attendance/verify-attendance/${recordId}`
    );
  }

  /**
   * Delete an attendance record by ID.
   * @param {number} recordId - The ID of the attendance record to delete.
   */
  deleteAttendanceRecord(recordId) {
    return request('DELETE', `/attendance/delete/${recordId}`);
  }

  verifyMultipleAttendanceRecords(recordIds) {
    return request('PUT', '/attendance/verify-attendance', recordIds);
  }

  deleteMultipleAttendanceRecords(recordIds) {
    return request('DELETE', '/attendance/delete', recordIds);
  }

  /**
   * Fetch attendance records for a specific teacher within a date range.
   * @param {number} teacherId - The ID of the teacher.
   * @param {string} startDate - The start date in YYYY-MM-DD format.
   * @param {string} endDate - The end date in YYYY-MM-DD format.
   */
  getTeacherRecordsByDateRange(teacherId, startDate, endDate) {
    return request(
      'GET',
      `/attendance/teacher/${teacherId}/date-range?startDate=${startDate}&endDate=${endDate}`
    );
  }
  getTeacherRecordsByDateRangeByPrimaryTeacher(
    teacherId,
    startDate,
    endDate
  ) {
    return request(
      'GET',
      `/attendance/teacher/${teacherId}/date-range/primary?startDate=${startDate}&endDate=${endDate}`
    );
  }

  getAllTeacherRecordsByDateRangeByPrimaryTeacher(
    startDate,
    endDate
  ) {
    return request(
      'GET',
      `/attendance/teachers/date-range/primary?startDate=${startDate}&endDate=${endDate}`
    );
  }

  getAllTeacherRecordsByDateRange(startDate, endDate) {
    return request(
      'GET',
      `/attendance/teachers/date-range?startDate=${startDate}&endDate=${endDate}`
    );
  }
}

// Assign the instance to a variable
const schoolServiceAdminInstance = new SchoolServiceAdmin();
// Export the instance
export default schoolServiceAdminInstance;
