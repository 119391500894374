import { Dialog } from '@headlessui/react';
import { useState } from 'react';

const AlternativeTeacherDialog = ({
  isOpen,
  onClose,
  allTeachers,
  selectedAlternativeTeacherId,
  setSelectedAlternativeTeacherId,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-30">
      <div className="fixed inset-0 bg-black bg-opacity-90" />
      <div className="fixed inset-0 flex items-center justify-center p-4 text-right">
        <Dialog.Panel className="w-full max-w-md bg-white rounded-lg p-6 shadow-lg">
          <Dialog.Title className="text-xl font-medium text-gray-900">
            اختر معلما بديلا
          </Dialog.Title>
          <div className="mt-4 text-xl max-h-60 overflow-y-auto">
            {allTeachers?.map((teacher) => (
              <div
                key={teacher.id}
                onClick={() =>
                  setSelectedAlternativeTeacherId(teacher.id)
                }
                className={`cursor-pointer p-4 rounded-md mb-4 border-2 ${
                  selectedAlternativeTeacherId === teacher.id
                    ? 'border-green-600 bg-green-100'
                    : 'border-gray-300 hover:border-green-500 hover:bg-gray-100'
                }`}
              >
                <p className="text-gray-700 font-semibold">
                  {teacher.firstName} {teacher.lastName} |{' '}
                  {teacher.classAbbrev}
                </p>
              </div>
            ))}
          </div>
          <div className="mt-6 flex justify-end space-x-4">
            <button
              onClick={onClose}
              className="rounded text-xl bg-gray-500 text-white px-4 py-2 font-semibold hover:bg-gray-600"
            >
              إلغاء
            </button>
            <button
              onClick={onClose}
              className="rounded text-xl bg-green-700 text-white px-4 py-2 font-semibold hover:bg-green-800"
            >
              تأكيد
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default AlternativeTeacherDialog;
