import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import schoolServiceAdminInstance from '../../services/SchoolServiceAdmin';

const HolidaysServices = ({ data }) => {
  const dateRef = useRef();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [allHolidays, setAllHolidays] = useState(null);

  // Check if user is a SUPER_ADMIN
  const isSuperAdmin = data.roles?.some(
    (role) => role.roleCode === 'SUPER_ADMIN'
  );

  useEffect(() => {
    if (!data || !isSuperAdmin) {
      localStorage.clear();
      navigate('/');
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const [holidays] = await Promise.all([
          schoolServiceAdminInstance.getAllHolidays(),
        ]);

        setAllHolidays(
          holidays.data.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };
    fetchData();
  }, [data, isSuperAdmin, navigate]);

  const createNewHolidayDate = async () => {
    try {
      // Check if a date is provided
      if (!dateRef.current.value) {
        alert('Please select a date before proceeding.');
        return; // Exit the function if no date is given
      }

      // Proceed to create a new holiday
      await schoolServiceAdminInstance.createNewHoliday(
        dateRef.current.value
      );
      refreshAllHolidays();
    } catch (error) {
      console.log(error);
    }
  };

  const refreshAllHolidays = async () => {
    setLoading(true);
    try {
      const response2 =
        await schoolServiceAdminInstance.getAllHolidays();
      setAllHolidays(
        response2.data.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        )
      );
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className="container mx-auto my-8">
      <h2 className="inline-flex items-center justify-center w-full md:text-2xl text-2xl text-center py-2 font-semibold text-cyan-900 ">
        Add or Remove Holidays
      </h2>
      <div className="inline-flex items-center justify-center w-full">
        <input
          className="m-3 py-2 block border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 hover:border-black transition-all duration-300"
          type="date"
          ref={dateRef}
        />
      </div>

      <div className="inline-flex items-center justify-center w-full">
        <button
          onClick={createNewHolidayDate}
          className="rounded bg-green-700 text-white px-6 py-2 font-semibold hover:bg-green-800"
        >
          + Add New Holiday
        </button>
      </div>

      {!loading && (
        <div className="lg:flex-wrap lg:flex md:flex-1 my-4">
          <table className="min-w-full border rounded overflow-hidden">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="py-2 px-4">ID</th>
                <th className="py-2 px-4">Date</th>
                <th className="py-2 px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {allHolidays?.map((holiday) => (
                <tr className="border-b text-center" key={holiday.id}>
                  <td className="py-2 px-4 ">{holiday.id}</td>
                  <td className="py-2 px-4 ">{holiday.date}</td>
                  <td className="py-2 px-4">
                    <button
                      onClick={async () => {
                        await schoolServiceAdminInstance.deleteHoliday(
                          holiday.id
                        );
                        refreshAllHolidays();
                      }}
                      className="rounded bg-red-700 text-white px-6 py-2 font-semibold hover:bg-red-800"
                    >
                      delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <hr className="w-960 h-2 mx-auto my-4 bg-cyan-800 border-0 rounded md:my-10 dark:bg-gray-700" />
      <div className="mx-auto text-center">
        <button
          onClick={() => {
            navigate('/schoolsuperadmin');
          }}
          className="rounded text-white px-6 py-2 font-semibold bg-cyan-700 hover:bg-cyan-800"
        >
          Back To Dashboard
        </button>
      </div>
    </div>
  );
};

export default HolidaysServices;
