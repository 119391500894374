import React, { useState } from 'react';
import BookService from '../../services/BookService';

const LogsDisplay = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isLogsVisible, setIsLogsVisible] = useState(false);

  const fetchLogs = async () => {
    // Validate input: Both startDate and endDate must be provided
    if (!startDate || !endDate) {
      alert('Please provide both start and end dates.');
      return;
    }

    try {
      setLoading(true);
      const logsResponse = await BookService.getLogs({
        startDate,
        endDate,
      });
      setLogs(logsResponse.data);
      setIsLogsVisible(true);
    } catch (error) {
      console.error('Error fetching logs:', error);
      setLogs([]);
    } finally {
      setLoading(false);
    }
  };

  const clearLogs = () => {
    setLogs([]);
    setStartDate('');
    setEndDate('');
    setIsLogsVisible(false);
  };

  return (
    <div className="my-6">
      <div className="flex flex-col items-center space-y-4">
        <h2 className="text-lg font-semibold text-cyan-900">
          Fetch Logs For Date Range
        </h2>
        <div className="grid grid-cols-2 gap-x-6 gap-y-4">
          <div className="flex flex-col">
            <label className="my-1 text-base text-center font-medium text-gray-700">
              Start Date
            </label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="py-1 block border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 hover:border-black transition-all duration-300"
            />
          </div>
          <div className="flex flex-col">
            <label className="my-1 text-base font-medium text-center text-gray-700">
              End Date
            </label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="py-1 block border-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 hover:border-black transition-all duration-300"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-6">
          <button
            onClick={fetchLogs}
            className="rounded bg-blue-600 text-white px-6 py-2 font-semibold hover:bg-blue-700"
          >
            Fetch Logs
          </button>
          <button
            onClick={clearLogs}
            className="rounded bg-gray-600 text-white px-6 py-2 font-semibold hover:bg-gray-700"
          >
            Clear and Hide
          </button>
        </div>
      </div>

      {isLogsVisible && (
        <div className="mt-6">
          {loading ? (
            <p>Loading logs...</p>
          ) : logs.length > 0 ? (
            <div className="overflow-y-auto h-96 border border-gray-300 rounded p-2 bg-gray-100 mt-2">
              {logs.map((log, index) => (
                <div key={index} className="mb-2 text-gray-700">
                  {log}
                </div>
              ))}
            </div>
          ) : (
            <p>No logs available for the selected date range.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default LogsDisplay;
